//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Cast from '@/helper/Cast';

/**
 * @see https://docs.oracle.com/cd/E19455-01/806-0169/overview-9/index.html
 */
class NumberFormat {
    static addLeadingZero(value, expectedStringLength) {
        const temporaryValue = '0'.repeat(expectedStringLength) + Cast.string(value);
        const finalValue     = temporaryValue.slice(expectedStringLength * -1);

        return finalValue;
    }

    static getValueString(value) {
        const floatValue  = Cast.float(value, 2);
        const fixedValue  = floatValue.toFixed(2);
        const valueString = Cast.string(fixedValue);

        return valueString;
    }

    /**
     * Format bytes as human-readable text.
     *
     * @param bytes Number of bytes.
     * @param si True to use metric (SI) units, aka powers of 1000. False to use
     *           binary (IEC), aka powers of 1024.
     * @param decimals Number of decimal places to display.
     *
     * @return Formatted string.
     */
    static toHumanReadableFileSize = (bytes, systemInternationalUnits = false, decimals = 0) => {
        const threshold     = systemInternationalUnits ? 1000 : 1024;
        let calculatedBytes = bytes;

        if (Math.abs(calculatedBytes) < threshold) {
            return `${calculatedBytes.toFixed(decimals)} B`;
        }

        const units = (
            systemInternationalUnits
                ? [
                    'kB',
                    'MB',
                    'GB',
                    'TB',
                    'PB',
                    'EB',
                    'ZB',
                    'YB',
                ]
                : [
                    'KiB',
                    'MiB',
                    'GiB',
                    'TiB',
                    'PiB',
                    'EiB',
                    'ZiB',
                    'YiB',
                ]
        );

        let unit   = -1;
        const rest = 10 ** decimals;

        do {
            calculatedBytes /= threshold;

            ++unit;
        } while (Math.round(Math.abs(calculatedBytes) * rest) / rest >= threshold && unit < units.length - 1);

        return `${calculatedBytes.toFixed(decimals)} ${units[unit]}`;
    };

    static toEnglishPunctuation(value) {
        const valueString      = NumberFormat.getValueString(value);
        const valueStringComma = valueString.replace('.', ',');

        return valueStringComma;
    }

    static toGermanPunctuation(value) {
        const valueString    = NumberFormat.getValueString(value);
        const valueStringDot = valueString.replace(',', '.');

        return valueStringDot;
    }
}

export default NumberFormat;
