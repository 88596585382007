//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { all }        from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { REHYDRATE }  from 'redux-persist';

// Actions
import { LoadingTypes }            from '@/store/actions/loading';
import { NavigationTypes }         from '@/store/actions/navigation';
import { PresentationParserTypes } from '@/store/actions/presentationParser';

// Sagas
import PresentationParserSagas from './presentationParser';
import LoadingSagas            from './loading';
import NavigationSagas         from './navigation';
import PreLoadSagas            from './preload';

function* root() {
    yield all([
        // @formatter:off
        takeLatest([LoadingTypes.OVERLAY_CLICKED],                   LoadingSagas.overlayClicked),
        takeLatest([NavigationTypes.REDIRECT],                       NavigationSagas.redirect),
        takeLatest([PresentationParserTypes.CLOSE_FILE],             PresentationParserSagas.closeFile),
        takeLatest([PresentationParserTypes.PROCESS_FILE_FAILED],    PresentationParserSagas.processFileFailed),
        takeLatest([PresentationParserTypes.PROCESS_FILE_SUCCEEDED], PresentationParserSagas.processFileSucceeded),
        takeLatest([REHYDRATE],                                      PreLoadSagas.preLoadData),
        // @formatter:on
    ]);
}

export default {
    root,
};
