//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { PresentationParserTypes }   from '@/store/actions/presentationParser';
import PowerPointParser              from '@/helper/PowerPointParser';
import { LoadingActions }            from '@/store/actions/loading';
import { PresentationParserActions } from '@/store/actions/presentationParser';

function presentationParserMiddleware() {
    return ({ dispatch, getState }) => (next) => (action) => {
        if (action.type === PresentationParserTypes.PROCESS_FILE) {
            const powerPointParser = new PowerPointParser();

            const updateCallback = (slidesLeft, imagesLeft) => {
                next(LoadingActions.setProgressLeft({
                    imagesLeft,
                    slidesLeft,
                }));
            };

            powerPointParser.parseFile(
                action.file,
                () => {
                    next(PresentationParserActions.processFileFailed());
                },
                (dataBuffer) => {
                    next(PresentationParserActions.processFileSucceeded({
                        dataBuffer,
                    }));
                },
                updateCallback,
            );
        }

        return next(action);
    };
}

export default presentationParserMiddleware;
