//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import PropTypes       from '@/components/PropTypes';
import ComponentHelper from '@/helper/ComponentHelper';
import styles          from './styles.module.scss';

export class Spacer extends React.Component {
    static propTypes = {
        height: PropTypes.number,
        width:  PropTypes.number,
    };

    static defaultProps = {
        height: 0,
        width:  0,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    getStyle = () => {
        const style  = {};
        const props  = this.props;
        const height = props.height;
        const width  = props.width;

        if (height) {
            style.height = height;
        }

        if (width) {
            style.width = width;
        }

        return style;
    };

    render() {
        const style = this.getStyle();

        return (
            <span
                className={styles.spacer}
                style={style}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default Spacer;
