//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Browser              from '@/helper/Browser';
import * as JSZip           from 'jszip';
import $                    from 'jquery'; // TODO: https://lulububu.atlassian.net/browse/CHARTWERK-22
import _                    from 'lodash';
import ImageStorage         from '@/helper/ImageStorage';
import EMFConverter         from '../thirdParty/emf';
import WMFConverter         from '../thirdParty/wmf';
import I18n                 from 'i18next';
import PictureStatesOnSlide from '@/constants/PictureStatesOnSlide';

class PowerPointParser {
    dataBuffer = {
        images:                   [],
        imageSizeSum:             0,
        fileSize:                 0,
        masterSlides:             0,
        slides:                   0,
        totalImageCount:          0,
        totalDuplicateImageCount: 0,
    };

    slideWidth = 0;

    slideHeight = 0;

    errorCallback = null;

    successCallbakc = null;

    fileList = null;

    fileReader = null;

    imageQueue = [];

    slideQueue = [];

    updateCallback = null;

    imagePathList = new Set();

    decodedFileSize = (base64) => {
        const base64Length          = base64.length;
        const endsWithOneEqualChar  = base64.endsWith('=') ? 1 : 0;
        const endsWithTwoEqualChars = base64.endsWith('==') ? 1 : 0;
        const reduceBy              = endsWithTwoEqualChars + endsWithOneEqualChar;
        const estimatedSize         = (
            base64Length /
            (
                4 * 3
            )
        );
        const result                = estimatedSize - reduceBy;

        return result;
    };

    fileError = () => {
        const { errorCallback } = this;

        if (errorCallback) {
            errorCallback();
        }
    };

    fileLoaded = (event) => {
        console.log('PowerPointParser: fileLoaded', event);

        const self = this;

        try {
            (
                JSZip
                    .loadAsync(event.target.result)
                    .then((zip) => {
                        console.log('PowerPointParser: fileLoaded, unzipping done');

                        const slidesFromZip = this.getSlideDescriptions(zip);

                        this.setSlideQueue(slidesFromZip);

                        this.processQueueEntryThreaded(zip, 10);
                    })
                    .catch((error) => {
                        console.log('PowerPointParser: fileLoaded, error', error);

                        self.fileError();
                    })
            );
        } catch (exception) {
            console.log('PowerPointParser: fileLoaded, error while loading zip file', exception);

            self.fileError();
        }
    };

    getFileExtension = (filePath) => {
        return filePath.split('.').pop();
    };

    getFileName = (filePath) => {
        const fileName = filePath.replace(/^.*[\\\/]/, '');

        return fileName;
    };

    getImageNameForXmlNode = (imageNode) => {
        console.log('PowerPointParser: getImageNameForXmlNode: imageNode', imageNode);

        const searchPath = (
            Browser.isChrome ?
                'cNvPr' :
                'p\\:cNvPr' // TODO: das sollte eine funktion sein die das prefix addet
        );

        const descriptionNode = imageNode.find(searchPath);

        if (descriptionNode.length > 0) {
            const name = descriptionNode.eq(0).attr('name');

            if (name && name.length > 0) {
                return name;
            }

            const description = descriptionNode.eq(0).attr('descr');

            if (description && description.length > 0) {
                return description;
            }
        }

        return '-';
    };

    getImagesFromRelationXML = (xml) => {
        // TODO: https://lulububu.atlassian.net/browse/CHARTWERK-22
        const images = xml.find('Relationship[Type*=\'image\']');

        console.log('PowerPointParser: getImagesFromRelationXML', xml, images);

        return images;
    };

    getImagesFromSlideXML = (xml) => {
        const searchPath = (
            Browser.isChrome ?
                'pic' :
                'p\\:pic'
        );
        const images     = xml.find(searchPath);

        console.log('PowerPointParser: getImagesFromSlideXML', xml, images);

        return images;
    };

    getSlideDescriptions = (zip) => {
        const slideDescriptions = [];
        const slideFolders      = [
            'slideLayouts',
            'slideMasters',
            'slides',
        ];
        const slideCounter      = {
            masterSlides: 0,
            slides:       0,
        };

        const presentationFile = zip.file('ppt/presentation.xml');

        if (presentationFile) {
            presentationFile.async('string').then((presentationXmlString) => {
                if (presentationXmlString) {
                    const xml        = $($.parseXML(presentationXmlString));
                    const dimensions = xml.find('p\\:sldSz');
                    const width      = dimensions.attr('cx');
                    const height     = dimensions.attr('cy');

                    if (
                        width &&
                        height
                    ) {
                        this.slideWidth  = width;
                        this.slideHeight = height;
                    }
                }
            });
        }

        for (const key in slideFolders) {
            const fileBuffer  = {};
            const slideFolder = slideFolders[key];

            zip.folder(`ppt/${slideFolder}/`).forEach((relativePath, file) => {
                if (relativePath.startsWith('slide')) {
                    const slideNumber = this.getSlideNumberFromPath(relativePath);

                    fileBuffer[slideNumber] = {
                        file,
                        page: slideNumber,
                    };
                }
            });

            zip.folder(`ppt/${slideFolder}/_rels/`).forEach((relativePath, relationFile) => {
                const slideNumber = this.getSlideNumberFromPath(relativePath);
                const slideData   = fileBuffer[slideNumber];
                const isMaster    = slideFolder !== 'slides';

                console.log('PowerPointParser: parse, iterating', slideNumber, relativePath, relationFile);

                const slideRelationData = {
                    relationFile,
                    slideFile:           slideData.file,
                    slideTitle:          slideData.title,
                    page:                slideData.page,
                    master:              isMaster,
                    stateOfImageOnSlide: slideData.stateOfImageOnSlide,
                };

                slideDescriptions.push(slideRelationData);

                if (isMaster) {
                    ++slideCounter.masterSlides;
                } else {
                    ++slideCounter.slides;
                }
            });
        }

        this.dataBuffer.masterSlides = slideCounter.masterSlides;
        this.dataBuffer.slides       = slideCounter.slides;

        return slideDescriptions;
    };

    getSlideNumberFromPath = (path) => {
        const regex  = /^(?:_rels\/|)slide(?:Master|)([0-9]+)\.xml(?:\.rels|)$/u;
        const result = path.match(regex);

        console.log('PowerPointParser: getSlideNumberFromPath', path, result);

        if (result) {
            return result[1];
        }

        return false;
    };

    getSlideTitleFromXmlString = (xmlString) => {
        console.log('PowerPointParser: getSlideTitleFromXmlString: xmlString');

        const regex2  = /<p:cSld name="(.*?)">/u;
        const result2 = xmlString.match(regex2);

        console.log('PowerPointParser: getSlideTitleFromXmlString: result2', result2);

        if (result2) {
            return result2[1];
        }

        const regex3  = /<p:txBody>(.*?)<\/p:txBody>/ug;
        const result3 = xmlString.match(regex3);

        if (result3) {
            const regex4  = /<a:t>(.*?)<\/a:t>/ug;
            const result4 = result3[0].match(regex4);

            console.log('PowerPointParser: getSlideTitleFromXmlString: result4', result4);

            if (result4) {
                const titleBuffer = [];

                for (const key in result4) {
                    const currentResult = result4[key];
                    const regex5        = /<a:t>(.*?)<\/a:t>/u;
                    const result5       = currentResult.match(regex5);

                    if (result5) {
                        titleBuffer.push(result5[1]);
                    }
                }

                return (
                    titleBuffer
                        .join(' ')
                        .replace('  ', ' ')
                        .replace(' ?', '?')
                );
            }
        }

        const regex  = /<a:t>(.*?)<\/a:t>/u;
        const result = xmlString.match(regex);

        console.log('PowerPointParser: getSlideTitleFromXmlString: result', result);

        if (result) {
            return result[1];
        }

        return false;
    };

    // TODO: wird das überhaupt gebraucht weil die klasse sowieso neu instanziert wird?
    reset = () => {
        this.fileReader                         = new FileReader();
        this.fileReader.onload                  = this.fileLoaded;
        this.fileReader.onabort                 = this.fileError;
        this.dataBuffer.images                  = [];
        this.dataBuffer.imageMetadataPowerPoint = new Map();
        this.dataBuffer.imageSizeSum            = 0;
        this.dataBuffer.fileSize                = 0;
        this.dataBuffer.slides                  = 0;
        this.dataBuffer.slideWidth              = 0;
        this.dataBuffer.slideHeight             = 0;
    };

    parseFile = (file, errorCallback, successCallback, updateCallback) => {
        console.log('PowerPointParser: parseFile: file', file);

        ImageStorage.clear();

        this.errorCallback   = errorCallback;
        this.updateCallback  = updateCallback;
        this.successCallback = successCallback;

        this.reset();

        if (file) {
            this.dataBuffer.fileName = file.name;
            this.dataBuffer.fileSize = file.size;

            try {
                this.fileReader.readAsBinaryString(file);
            } catch (exception) {
                console.log('PowerPointParser: parseFile: error', exception);

                errorCallback();
            }
        } else {
            errorCallback();
        }
    };

    parseXml = (fileContent) => {
        // TODO: https://lulububu.atlassian.net/browse/CHARTWERK-22
        const xml = $($.parseXML(fileContent));

        return xml;
    };

    processNextImageQueueEntry = (zip) => {
        const currentImageData = this.imageQueue.shift();
        const self             = this;

        console.log('PowerPointParser: processNextImageQueueEntry', currentImageData);

        const file = zip.file(currentImageData.path);

        if (file) {
            console.log('PowerPointParser: processNextImageQueueEntry: image file opening succeeded');

            (
                file
                    .async('base64')
                    .then(
                        (image) => {
                            console.log('PowerPointParser: processNextImageQueueEntry: image file reading succeeded');

                            const imageType = this.getFileExtension(currentImageData.path);
                            const base64    = `data:image/${imageType};base64,${image}`;

                            const imageConversionDone = (currentImageData, imageType, base64) => {
                                console.log('PowerPointParser: imageConversionDone: imageType', imageType);

                                const currentImage = new Image();
                                const fileSize     = this.decodedFileSize(base64);

                                (
                                    self
                                        .resizeDataURL(base64)
                                        .then((resizedImage) => {
                                            console.log('PowerPointParser: image successfully downsized for preview');

                                            this.dataBuffer.imageSizeSum += fileSize;

                                            const newImageFound = {
                                                conversionError:     currentImageData.conversionError,
                                                height:              null,
                                                imageAsBase64:       resizedImage,
                                                imageTitle:          currentImageData.imageTitle,
                                                fileName:            this.getFileName(currentImageData.path),
                                                fileSize,
                                                master:              currentImageData.master,
                                                page:                currentImageData.page,
                                                path:                currentImageData.path,
                                                slideTitle:          currentImageData.slideTitle,
                                                stateOfImageOnSlide: currentImageData.stateOfImageOnSlide,
                                                type:                imageType,
                                                width:               null,
                                            };

                                            currentImage.onload  = () => {
                                                console.log('PowerPointParser: processNextImageQueueEntry: currentImage.onload');

                                                newImageFound.height = currentImage.height;
                                                newImageFound.width  = currentImage.width;
                                                newImageFound.pixels = newImageFound.height * newImageFound.width;

                                                (
                                                    ImageStorage
                                                        .saveImage(base64)
                                                        .then(
                                                            (id) => {
                                                                console.log('PowerPointParser: processNextImageQueueEntry: ImageStorage: done', id);

                                                                newImageFound.fullImageStoreId = id;

                                                                this.dataBuffer.images.push(newImageFound);

                                                                this.processQueueEntry(zip);
                                                            },
                                                            (error) => {
                                                                console.log('PowerPointParser: processNextImageQueueEntry: ImageStorage error', error);

                                                                this.processQueueEntry(zip);
                                                            },
                                                        )
                                                );
                                            };
                                            currentImage.onerror = () => {
                                                console.log('PowerPointParser: processNextImageQueueEntry: currentImage.onerror');

                                                newImageFound.fullImageStoreId = false;

                                                this.dataBuffer.images.push(newImageFound);

                                                this.processQueueEntry(zip);
                                            };

                                            currentImage.src = base64;
                                        }).catch((error) => {
                                        console.log('PowerPointParser: processNextImageQueueEntry: error', error);

                                        this.processQueueEntry(zip);
                                    })
                                );
                            };

                            // TODO: auslagern in image-converter oder library verwenden
                            // TODO: https://lulububu.atlassian.net/browse/CHARTWERK-80
                            if (
                                imageType === 'tif' ||
                                imageType === 'tiff'
                            ) {
                                console.log('PowerPointParser: processNextImageQueueEntry: got tif or tiff image, converting');

                                const xhr        = new XMLHttpRequest();
                                xhr.responseType = 'arraybuffer';
                                xhr.onload       = (event) => {
                                    try {
                                        const tiff    = new Tiff({
                                            buffer: xhr.response,
                                        });
                                        const canvas  = tiff.toCanvas();
                                        const pngCopy = canvas.toDataURL('image/png');
                                        base64        = pngCopy;

                                        imageConversionDone(currentImageData, imageType, base64);
                                    } catch (exception) {
                                        currentImageData.conversionError = true;
                                        imageConversionDone(currentImageData, imageType, base64);
                                    }
                                };
                                xhr.onerror      = () => {
                                    currentImageData.conversionError = true;
                                    imageConversionDone(currentImageData, imageType, base64);
                                };
                                xhr.open('GET', base64);
                                xhr.send();
                            } else if (imageType === 'wmf') {
                                console.log('PowerPointParser: processNextImageQueueEntry: got wmf image, converting');

                                const xhr        = new XMLHttpRequest();
                                xhr.responseType = 'arraybuffer';
                                xhr.onload       = function (event) {
                                    try {
                                        const wmf    = new WMFConverter();
                                        const canvas = document.createElement('canvas');

                                        wmf.toCanvas(new DataView(xhr.response), canvas);

                                        const pngCopy = canvas.toDataURL('image/png');
                                        base64        = pngCopy;

                                        imageConversionDone(currentImageData, imageType, base64);
                                    } catch (exception) {
                                        currentImageData.conversionError = true;
                                        imageConversionDone(currentImageData, imageType, base64);
                                    }
                                };
                                xhr.onerror      = function () {
                                    currentImageData.conversionError = true;
                                    imageConversionDone(currentImageData, imageType, base64);
                                };
                                xhr.open('GET', base64);
                                xhr.send();
                            } else if (
                                imageType === 'emf' &&
                                base64.length <= 500000
                            ) {
                                console.log('PowerPointParser: processNextImageQueueEntry: got emf image, converting. Length: ', base64.length);

                                const xhr        = new XMLHttpRequest();
                                xhr.responseType = 'arraybuffer';
                                xhr.onload       = function (event) {
                                    console.log('emf loaded', base64.length);

                                    try {
                                        const emf    = new EMFConverter();
                                        const canvas = document.createElement('canvas');

                                        emf.toCanvas(new DataView(xhr.response), canvas);

                                        const pngCopy = canvas.toDataURL('image/png');
                                        base64        = pngCopy;

                                        console.log('emf converted', base64.length);

                                        imageConversionDone(currentImageData, imageType, base64);
                                    } catch (exception) {
                                        console.log('emf convert error', exception);
                                        currentImageData.conversionError = true;
                                        imageConversionDone(currentImageData, imageType, base64);
                                    }
                                };
                                xhr.onerror      = function () {
                                    console.log('emf load error', exception);
                                    currentImageData.conversionError = true;
                                    imageConversionDone(currentImageData, imageType, base64);
                                };
                                xhr.open('GET', base64);
                                xhr.send();
                            } else {
                                console.log('PowerPointParser: processNextImageQueueEntry: got a default file type, continuing without conversion');

                                imageConversionDone(currentImageData, imageType, base64);
                            }
                        },
                        (error) => {
                            console.log('PowerPointParser: processNextImageQueueEntry: image file reading failed');

                            this.processQueueEntry(zip);
                        },
                    )
            );
        } else {
            console.log('PowerPointParser: processNextImageQueueEntry: image file opening failed');

            this.processQueueEntry(zip);
        }
    };

    processNextSlideQueueEntry = (zip) => {
        const currentSlide = this.slideQueue.shift();
        const self         = this;

        console.log('PowerPointParser: processNextSlideQueueEntry', currentSlide);

        currentSlide.slideFile.async('string').then((slideFileContent) => {
            currentSlide.relationFile.async('string').then((relationFileContent) => {
                console.log('PowerPointParser: processNextSlideQueueEntry: slideFileContent', currentSlide);

                const relationXml    = self.parseXml(relationFileContent);
                const slideXml       = self.parseXml(slideFileContent);
                const relationImages = self.getImagesFromRelationXML(relationXml);
                const slideImages    = self.getImagesFromSlideXML(slideXml);
                const slideTitle     = self.getSlideTitleFromXmlString(slideFileContent);
                const slidesLength   = $(slideImages).length;

                console.log('PowerPointParser: processNextSlideQueueEntry: relationXml', relationXml);
                console.log('PowerPointParser: processNextSlideQueueEntry: slideImages', slideImages);

                for (let i = 0; i < slideImages.length; ++i) {
                    const slideImage      = $(slideImages[slidesLength - 1 - i]);
                    const imageTitle      = self.getImageNameForXmlNode(slideImage);
                    let imagePathRelative = this.getPathFromImage(slideImage, relationImages);

                    if (imagePathRelative !== '') {
                        imagePathRelative = imagePathRelative.replace('../', '');
                        const finalPath   = `ppt/${imagePathRelative}`;

                        if (this.imagePathList.has(finalPath)) {
                            this.dataBuffer.totalDuplicateImageCount++;
                        } else {
                            this.imagePathList.add(finalPath);
                        }

                        const fileData = {
                            conversionError:     false,
                            imageTitle,
                            path:                finalPath,
                            page:                currentSlide.page,
                            master:              currentSlide.master,
                            slideTitle,
                            stateOfImageOnSlide: this.getPowerPointImageInformation(slideImage),
                        };

                        this.dataBuffer.totalImageCount++;
                        self.imageQueue.push(fileData);
                    }
                }

                self.processQueueEntry(zip);
            });
        });
    };

    getPathFromImage = (image, relationImages) => {
        const imageId = image.find('a\\:blip').attr('r:embed');
        let path      = '';

        relationImages.each((index, relationImage) => {
            const relationImageParsed = $(relationImage);
            const relationImageId     = relationImageParsed.attr('Id');

            if (relationImageId === imageId) {
                path = relationImageParsed.attr('Target');
            }
        });

        return path;
    };

    getPowerPointImageInformation = (imageXml) => {
        const powerPointPicture = imageXml.find('p\\:spPr').find('a\\:xfrm');

        if (powerPointPicture) {
            const powerPointPicturePosition = powerPointPicture.find('a\\:off');
            const xPosition                 = Number(powerPointPicturePosition.attr('x'));
            const yPosition                 = Number(powerPointPicturePosition.attr('y'));

            const powerPointPictureDimension = powerPointPicture.find('a\\:ext');
            const pictureWidth               = Number(powerPointPictureDimension.attr('cx'));
            const pictureHeight              = Number(powerPointPictureDimension.attr('cy'));

            if (
                xPosition &&
                yPosition &&
                pictureWidth &&
                pictureHeight
            ) {
                return this.getPositionOfPictureOnSlide(xPosition, yPosition, pictureWidth, pictureHeight);
            }
        }

        return PictureStatesOnSlide.completelyInside;
    };

    processQueueEntry = (zip) => {
        console.log(
            'PowerPointParser: processQueueEntry, queues',
            this.slideQueue.length,
            this.imageQueue.length,
        );

        this.postUpdate();

        if (this.imageQueue.length > 0) {
            this.processNextImageQueueEntry(zip);
        } else if (this.slideQueue.length > 0) {
            this.processNextSlideQueueEntry(zip);
        } else {
            this.successCallback(this.dataBuffer);
        }
    };

    processQueueEntryThreaded = (zip, numberOfThreads) => {
        for (let threadNumber = 0; threadNumber < numberOfThreads; ++threadNumber) {
            this.processQueueEntry(zip);
        }
    };

    postUpdate = () => {
        this.updateCallback(
            this.slideQueue.length,
            this.imageQueue.length,
        );
    };

    resizeDataURL = (base64) => {
        console.log('PowerPointParser: resizeDataURL: called', base64);

        return new Promise(async (resolve, reject) => {
            const image = document.createElement('img');

            // This has to be "function" to allow "this" to become a pointer to the image
            image.onload  = function () {
                console.log('PowerPointParser: resizeDataURL: image loaded');

                const targetRatio  = 100 / image.width;
                const canvas       = document.createElement('canvas');
                const ctx          = canvas.getContext('2d');
                const wantedWidth  = image.width * targetRatio;
                const wantedHeight = image.height * targetRatio;
                canvas.width       = wantedWidth;
                canvas.height      = wantedHeight;

                ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

                const dataURI = canvas.toDataURL();

                console.log('PowerPointParser: resizeDataURL: image downsized');

                resolve(dataURI);
            };
            image.onerror = () => {
                console.log('PowerPointParser: resizeDataURL: image loading error, falling back to original file');

                resolve(base64);
            };
            image.src     = base64;
        });
    };

    checkIfPointInArea(point, area) {
        const { x, y } = point;

        return (
            x >= area.x &&
            x <= area.x + area.width &&
            y >= area.y &&
            y <= area.y + area.height
        );
    }

    getPositionOfPictureOnSlide(xPosition, yPosition, pictureWidth, pictureHeight) {
        const area                    = {
            x:      0,
            y:      0,
            width:  this.slideWidth,
            height: this.slideHeight,
        };
        const pictureUpperLeftCorner  = {
            x: xPosition,
            y: yPosition,
        };
        const pictureUpperRightCorner = {
            x: xPosition + pictureWidth,
            y: yPosition,
        };
        const pictureLowerLeftCorner  = {
            x: xPosition,
            y: yPosition + pictureHeight,
        };
        const pictureLowerRightCorner = {
            x: xPosition + pictureWidth,
            y: yPosition + pictureHeight,
        };
        const corners                 = {
            pictureUpperLeftCorner,
            pictureUpperRightCorner,
            pictureLowerLeftCorner,
            pictureLowerRightCorner,
        };

        if (
            xPosition < area.x ||
            yPosition < area.y ||
            xPosition + pictureWidth > this.slideWidth ||
            yPosition + pictureHeight > this.slideHeight
        ) {
            if (this.checkIfImageIsOutsideSlide(corners, area)) {
                return PictureStatesOnSlide.partiallyOutside;
            }

            return PictureStatesOnSlide.completelyOutside;
        }

        return PictureStatesOnSlide.completelyInside;
    }

    checkIfImageIsOutsideSlide = (corners, area) => {
        const { pictureUpperLeftCorner, pictureUpperRightCorner, pictureLowerLeftCorner, pictureLowerRightCorner } = corners;

        return (
            !this.checkIfPointInArea(pictureUpperLeftCorner, area) &&
            !this.checkIfPointInArea(pictureUpperRightCorner, area) &&
            !this.checkIfPointInArea(pictureLowerLeftCorner, area) &&
            !this.checkIfPointInArea(pictureLowerRightCorner, area)
        );
    };

    setSlideQueue = (slideQueue) => {
        this.slideQueue = slideQueue;
    };
}

export default PowerPointParser;
