//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                          from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import ComponentHelper                from '@/helper/ComponentHelper';
import PropTypes                      from '@/components/PropTypes';

export default class Link extends React.Component {
    static propTypes = {
        children:  PropTypes.children,
        className: PropTypes.string,
        hash:      PropTypes.string,
        search:    PropTypes.object,
        target:    PropTypes.string,
        to:        PropTypes.string,
    };

    static defaultProps = {
        children:  [],
        className: '',
        hash:      '',
        search:    {},
        target:    null,
        to:        null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    getToParameter = () => {
        const pathname     = this.props.to;
        const search       = Object.entries(this.props.search).map(([key, value]) => {
            return `${key}=${value}`;
        }).join('&');
        const { hash }     = this.props;
        const toParameters = {
            pathname,
            search: search ? `?${search}` : '',
            hash:   hash ? `#${hash}` : '',
        };

        return toParameters;
    };

    stopPropagation = (event) => {
        event.stopPropagation();
    };

    render() {
        return (
            <ReactRouterDomLink
                className={this.props.className}
                to={this.getToParameter()}
                target={this.props.target}
                onClick={this.stopPropagation}
            >
                {this.props.children}
            </ReactRouterDomLink>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}
