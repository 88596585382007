//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n            from 'i18next';
import React           from 'react';
import DropZone        from '@/components/stateless/atomic/DropZone';
import Paragraph       from '@/components/stateless/atomic/Paragraph';
import Spacer          from '@/components/stateless/atomic/Spacer';
import MultiColumnText from '@/components/stateless/atomic/MultiColumnText';
import PageTitle       from '@/components/stateless/composed/PageTitle';
import styles          from './styles.module.scss';

export default class Home extends React.Component {
    render() {
        return (
            <div className={styles.homeWrapper}>
                <PageTitle
                    title={I18n.t('homePage')}
                />
                <h2 className={styles.slogan}>
                    <span className={styles.line}>
                        <strong>
                            {I18n.t('homeSloganPart1')}
                        </strong>
                        {I18n.t('homeSloganPart2')}
                        <strong>
                            {I18n.t('homeSloganPart3')}
                        </strong>
                    </span>
                    <br />
                    {I18n.t('homeSloganPart4')}
                    <strong className={styles.red}>
                        {I18n.t('homeSloganPart5')}
                    </strong>
                    {I18n.t('homeSloganPart6')}
                    <br />
                    <strong className={styles.red}>
                        {I18n.t('homeSloganPart7')}
                    </strong>
                </h2>
                <Spacer height={24} />
                <MultiColumnText>
                    <Paragraph>
                        {I18n.t('homeText1')}
                    </Paragraph>
                    <Paragraph>
                        {I18n.t('homeText2')}
                    </Paragraph>
                    <Paragraph>
                        {I18n.t('homeText3')}
                    </Paragraph>
                    <Paragraph>
                        {I18n.t('homeText4')}
                    </Paragraph>
                    <Paragraph>
                        {I18n.t('homeText5')}
                    </Paragraph>
                </MultiColumnText>
                <Spacer height={24} />
                <DropZone />
            </div>
        );
    }
}
