//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// @see https://www.w3schools.com/jsref/dom_obj_event.asp
const domEvents = Object.freeze({
    abort:              'abort',
    afterPrint:         'afterprint',
    animationEnd:       'animationend',
    animationIteration: 'animationiteration',
    animationStart:     'animationstart',
    beforePrint:        'beforeprint',
    beforeUnload:       'beforeunload',
    blur:               'blur',
    canPlay:            'canplay',
    canPlayThrough:     'canplaythrough',
    change:             'change',
    click:              'click',
    contextMenu:        'contextmenu',
    copy:               'copy',
    cut:                'cut',
    doubleClick:        'dblclick',
    drag:               'drag',
    dragEnd:            'dragend',
    dragEnter:          'dragenter',
    dragLeave:          'dragleave',
    dragOver:           'dragover',
    dragStart:          'dragstart',
    drop:               'drop',
    durationChange:     'durationchange',
    ended:              'ended',
    error:              'error',
    focus:              'focus',
    focusIn:            'focusin',
    focusOut:           'focusout',
    fullScreenChange:   'fullscreenchange',
    fullScreenError:    'fullscreenerror',
    hashChange:         'hashchange',
    input:              'input',
    invalid:            'invalid',
    keyDown:            'keydown',
    keyPress:           'keypress',
    keyUp:              'keyup',
    load:               'load',
    loadedData:         'loadeddata',
    loadedMetaData:     'loadedmetadata',
    loadStart:          'loadstart',
    message:            'message',
    mouseDown:          'mousedown',
    mouseEnter:         'mouseenter',
    mouseLeave:         'mouseleave',
    mouseMove:          'mousemove',
    mouseOver:          'mouseover',
    mouseOut:           'mouseout',
    mouseUp:            'mouseup',
    mouseWheel:         'mousewheel',
    offline:            'offline',
    online:             'online',
    open:               'open',
    pageHide:           'pagehide',
    pageShow:           'pageshow',
    paste:              'paste',
    pause:              'pause',
    play:               'play',
    playing:            'playing',
    popState:           'popstate',
    progress:           'progress',
    rateChange:         'ratechange',
    resize:             'resize',
    reset:              'reset',
    scroll:             'scroll',
    search:             'search',
    seeked:             'seeked',
    seeking:            'seeking',
    select:             'select',
    show:               'show',
    stalled:            'stalled',
    storage:            'storage',
    submit:             'submit',
    suspend:            'suspend',
    timeUpdate:         'timeupdate',
    toggle:             'toggle',
    touchCancel:        'touchcancel',
    touchEnd:           'touchend',
    touchMove:          'touchmove',
    touchStart:         'touchstart',
    transitionEnd:      'transitionend',
    unload:             'unload',
    volumeChange:       'volumechange',
    waiting:            'waiting',
    wheel:              'wheel',
});

module.exports = domEvents;
