//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                   from 'react';
import { connect }             from 'react-redux';
import _                       from 'lodash';
import I18n                    from 'i18next';
import $                       from 'jquery'; // TODO: https://lulububu.atlassian.net/browse/CHARTWERK-28
import { bindActionCreators }  from 'redux';
import classNames              from 'classnames';
import ReactTooltip            from 'react-tooltip';
import ImageType               from '@/constants/ImageType';
import { LightBoxActions }     from '@/store/actions/lightBox';
import { FontAwesomeIcon }     from '@fortawesome/react-fontawesome';
import { faSortDown }          from '@fortawesome/free-solid-svg-icons';
import { faSortUp }            from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import PageTitle               from '@/components/stateless/composed/PageTitle';
import withWindowDimensions    from '@/components/hoc/ResizeListener';
import AnalysisInfo            from '@/components/connected/AnalysisInfo';
import Cast                    from '@/helper/Cast';
import NumberFormat            from '@/helper/NumberFormat';
import PropTypes               from '@/components/PropTypes';
import PictureStatesOnSlide    from '@/constants/PictureStatesOnSlide';
import TableColumn             from './TableColumn';
import SortDirection           from './SortDirection';
import styles                  from './styles.module.scss';

const mapStateToProps = (state) => {
    return {
        images: _.get(state, 'presentationParser.images', []),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(LightBoxActions, dispatch);
};

class Analyse extends React.Component {
    static propTypes = {
        windowHeight:    PropTypes.number,
        windowWidth:     PropTypes.number,
        openWithStoreId: PropTypes.number,
        images:          PropTypes.array,
    };

    static defaultProps = {
        windowHeight:    0,
        windowWidth:     0,
        openWithStoreId: 0,
        images:          [],
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    componentDidMount() {
        this.resizeHeaders();

        $('#table-wrapper').scroll(() => {
            const element    = $(this);
            const scrollLeft = element.scrollLeft();

            $('#table-head').css('margin-left', scrollLeft * -1);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.windowHeight !== this.props.windowHeight ||
            prevProps.windowWidth !== this.props.windowWidth
        ) {
            this.resizeHeaders();
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            activeSortColumn: {
                column:    TableColumn.slide,
                direction: SortDirection.ascending,
            },
        };
    }

    imagePreviewClicked = (fullImageStoreId) => () => {
        this.props.openWithStoreId({
            storeId: fullImageStoreId,
        });
    };

    render() {
        // For unknown reasons we have to run this more often to make sure the
        // table width is calculated properly
        this.resizeHeaders();
        ReactTooltip.rebuild();

        return (
            <div
                className={styles.analyseWrapper}
                key={this.props.images.length}
            >
                <PageTitle
                    title={I18n.t('analyse')}
                />
                <div
                    className={styles.tableWrapper}
                    id={'table-wrapper'}
                >
                    <table className={styles.fixed}>
                        <thead id={'table-head'}>
                            <tr>
                                <th className={styles.noSorting}>
                                    <div className={styles.paddingWrapper}>
                                        {I18n.t('imagePreview')}
                                    </div>
                                </th>
                                {this.renderTableHeader(
                                    TableColumn.slide,
                                    I18n.t('slide'),
                                )}
                                {this.renderTableHeader(
                                    TableColumn.fileName,
                                    I18n.t('fileName'),
                                )}
                                {this.renderTableHeader(
                                    TableColumn.fileType,
                                    I18n.t('fileType'),
                                )}
                                {this.renderTableHeader(
                                    TableColumn.fileSize,
                                    I18n.t('fileSize'),
                                )}
                                {this.renderTableHeader(
                                    TableColumn.imageResolution,
                                    I18n.t('imageResolution'),
                                )}
                            </tr>
                        </thead>
                    </table>
                    <table>
                        <tbody>
                            {this.renderImageRows()}
                        </tbody>
                    </table>
                    <AnalysisInfo />
                </div>
            </div>
        );
    }

    renderImageRow = (imageData, rowIndex) => {
        // It may happen that two images are used twice or more often on a slide so
        // we have to use the row index here, too
        const key = `${imageData.fileName}_${imageData.path}_${rowIndex}`;

        return (
            <tr key={key}>
                <td>
                    <div className={styles.paddingWrapper}>
                        {this.renderImagePreview(imageData, rowIndex)}
                    </div>
                </td>
                <td className={styles.fullWidth}>
                    <div className={styles.paddingWrapper}>
                        <strong>
                            {this.renderSlideNumber(imageData)}
                        </strong>
                        {this.renderSlideTitle(imageData)}
                    </div>
                </td>
                <td>
                    <div className={styles.paddingWrapper}>
                        {this.renderFileName(imageData)}
                    </div>
                </td>
                <td>
                    <div className={styles.paddingWrapper}>
                        {this.renderImageType(imageData)}
                        {this.renderImageTypeWarning(imageData)}
                    </div>
                </td>
                <td>
                    <div className={styles.paddingWrapper}>
                        {this.renderImageSize(imageData)}
                    </div>
                </td>
                <td>
                    <div className={styles.paddingWrapper}>
                        {this.renderImageResolution(imageData)}
                    </div>
                </td>
            </tr>
        );
    };

    renderImageRows = () => {
        const { images } = this.props;

        if (
            images &&
            images.length
        ) {
            let sortedRows = _.sortBy(
                images,
                this.sortDataCallback,
            );

            if (this.state.activeSortColumn.direction === SortDirection.descending) {
                sortedRows = sortedRows.reverse();
            }

            return sortedRows.map(this.renderImageRow);
        }

        return (
            <tr className={'empty'}>
                <td colSpan={6}>
                    <div
                        className={classNames(
                            styles.paddingWrapper,
                            styles.paddingWrapperCenterText,
                        )}
                    >
                        {I18n.t('analyseEmpty')}
                    </div>
                </td>
            </tr>
        );
    };

    renderFileName = (imageData) => {
        const { fileName } = imageData;

        if (fileName) {
            return fileName;
        }

        return '-';
    };

    renderImagePreview = (imageData, index) => {
        const { height, width, type } = imageData;
        let notInFrameWarning         = null;

        if (
            this.isImageTypeSupported(type) &&
            height &&
            width
        ) {
            const style       = {
                backgroundImage: `url("${imageData.imageAsBase64}")`,
            };
            notInFrameWarning = this.renderNotInFrameWarning(imageData);

            return (
                <div className={styles.imageWrapper}>
                    <div
                        className={styles.image}
                        onClick={this.imagePreviewClicked(imageData.fullImageStoreId)}
                        style={style}
                    />
                    {notInFrameWarning}
                </div>
            );
        }

        return this.renderEmptyImagePreview();
    };

    renderEmptyImagePreview = () => {
        return (
            <div className={styles.emptyImageWrapper}>
                {this.renderWarningIcon(I18n.t('unparsableImage'))}
            </div>
        );
    };

    renderImageResolution = (imageData) => {
        const { height, width } = imageData;

        if (
            height &&
            width
        ) {
            return [
                width,
                'x',
                height,
                I18n.t('pixelShort'),
            ].join(' ');
        }

        return null;
    };

    renderImageSize = (imageData) => {
        return NumberFormat.toHumanReadableFileSize(imageData.fileSize, false, 2);
    };

    renderImageType = (imageData) => {
        return imageData.type;
    };

    isImageTypeSupported = (imageType) => {
        return (
            imageType !== ImageType.emf &&
            imageType !== ImageType.wmf &&
            imageType !== ImageType.svg
        );
    };

    renderImageTypeWarning = (imageData) => {
        if (!this.isImageTypeSupported(imageData.type)) {
            return this.renderWarningIcon(I18n.t('vectorFile'));
        }

        return null;
    };

    renderWarningIcon = (tooltipText) => {
        return (
            <span
                data-tip={tooltipText}
                className={styles.tooltipWrapper}
            >
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                />
            </span>
        );
    };

    renderSlideNumber = (imageData) => {
        if (imageData.master) {
            return I18n.t('masterSlide');
        }

        return imageData.page;
    };

    renderSlideTitle = (imageData) => {
        const { slideTitle } = imageData;

        if (
            slideTitle &&
            slideTitle.length
        ) {
            return (
                <>
                    {' ( '}
                    <span className={styles.limit}>
                        {slideTitle}
                    </span>
                    {')'}
                </>
            );
        }

        return null;
    };

    renderSortIcons = () => {
        return (
            <>
                <span
                    className={classNames(
                        styles.icon,
                        styles.iconAscending,
                    )}
                >
                    <FontAwesomeIcon
                        icon={faSortUp}
                    />
                </span>
                <span
                    className={classNames(
                        styles.icon,
                        styles.iconDescending,
                    )}
                >
                    <FontAwesomeIcon
                        icon={faSortDown}
                    />
                </span>
            </>
        );
    };

    renderTableHeader = (column, title) => {
        console.log('renderTableHeader', column, title);

        const { activeSortColumn } = this.state;

        return (
            <th>
                <div
                    className={classNames(
                        styles.paddingWrapper,
                        {
                            [styles.sortingActive]:              activeSortColumn.column === column,
                            [styles.sortingDirectionAscending]:  activeSortColumn.direction === SortDirection.ascending,
                            [styles.sortingDirectionDescending]: activeSortColumn.direction === SortDirection.descending,
                        },
                    )}
                    onClick={this.toggleSorting(column)}
                >
                    {title}
                    {this.renderSortIcons()}
                </div>
            </th>
        );
    };

    // TODO: https://lulububu.atlassian.net/browse/CHARTWERK-28
    resizeHeaders = () => {
        const tableHeaders  = $('thead th');
        const firstTableRow = $('tbody tr:eq(0)');

        if (!firstTableRow.hasClass('empty')) {
            const tableCells       = firstTableRow.find('td');
            const jWindow          = $(window);
            const windowHeight     = jWindow.height();
            const tableViewWrapper = $('#table-wrapper');
            const autowidth        = {
                minWidth: 'auto',
                width:    'auto',
            };

            (
                tableHeaders
                    .css(autowidth)
                    // eslint-disable-next-line rulesdir/no-shortcut-variable-name
                    .eq(1)
                    .css('width', '100%')
            );

            tableCells.css(autowidth);

            tableViewWrapper.css('max-height', windowHeight - 50);

            tableCells.each((index, element) => {
                const tdElement   = $(element);
                // eslint-disable-next-line rulesdir/no-shortcut-variable-name
                const thElement   = tableHeaders.eq(index);
                const columnTitle = thElement.text();
                const tdWidth     = tdElement.outerWidth();
                const thWidth     = thElement.outerWidth();
                let finalWidth    = Math.max(tdWidth, thWidth);

                if (index === 1) {
                    // This fixes a bug that the table is rendered too wide
                    finalWidth -= 60;
                }

                console.log('resizeHeaders', columnTitle, tdWidth, thWidth, finalWidth);

                [thElement, tdElement].forEach((target) => {
                    (
                        target
                            .width(finalWidth)
                            .css('min-width', finalWidth)
                    );
                });
            });
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    sortDataCallback = (imageData) => {
        switch (this.state.activeSortColumn.column) {
            case TableColumn.fileName:
                return imageData.fileName;

            case TableColumn.fileType:
                return imageData.type;

            case TableColumn.fileSize:
                return imageData.fileSize;

            case TableColumn.imageResolution:
                return imageData.height * imageData.width;

            case TableColumn.slide:
                if (imageData.master) {
                    return 0;
                }

                return Cast.int(imageData.page);
        }

        return null;
    };

    toggleSorting = (column) => () => {
        console.log('Analyse: toggleSorting', column);

        const { activeSortColumn } = this.state;
        let nextDirection          = activeSortColumn.direction;

        if (activeSortColumn.column === column) {
            nextDirection = (
                nextDirection === SortDirection.ascending ?
                    SortDirection.descending :
                    SortDirection.ascending
            );
        }

        const nextState = {
            ...this.state,
            activeSortColumn: {
                column,
                direction: nextDirection,
            },
        };

        console.log('Analyse: nextState', nextState);

        this.setState(nextState);
    };

    renderNotInFrameWarning(image) {
        const imageState = image.stateOfImageOnSlide;

        if (imageState !== PictureStatesOnSlide.completelyInside) {
            const warningText = (
                image.stateOfImageOnSlide === PictureStatesOnSlide.completelyOutside ?
                    I18n.t('pictureCompletelyOutside') :
                    I18n.t('picturePartiallyOutside')
            );

            return this.renderWarningIcon(warningText);
        }

        return null;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withWindowDimensions(Analyse));
