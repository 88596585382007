//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import { connect }                   from 'react-redux';
import { bindActionCreators }        from 'redux';
import ComponentHelper               from '@/helper/ComponentHelper';
import PropTypes                     from '@/components/PropTypes';
import MainMenuStateless             from '@/components/stateless/composed/MainMenu';
import { PresentationParserActions } from '@/store/actions/presentationParser';
import _                             from 'lodash';

const mapStateToProps = (state) => {
    return {
        fileName: _.get(state, 'presentationParser.fileName', null),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(PresentationParserActions, dispatch);
};

class MainMenu extends React.Component {
    static propTypes = {
        fileName:  PropTypes.string,
        closeFile: PropTypes.func,
    };

    static defaultProps = {
        fileName:  null,
        closeFile: _.noop,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    closeFileButtonPressed = () => {
        this.props.closeFile();
    };

    render() {
        return (
            <MainMenuStateless
                closeFileButtonPressed={this.closeFileButtonPressed}
                openFileName={this.props.fileName}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
