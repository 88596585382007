//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                   from 'react';
import { bindActionCreators }  from 'redux';
import { connect }             from 'react-redux';
import _                       from 'lodash';
import ComponentHelper         from '@/helper/ComponentHelper';
import PropTypes               from '@/components/PropTypes';
import StatelessLoadingOverlay from '@/components/stateless/atomic/LoadingOverlay';
import { LoadingActions }      from '@/store/actions/loading';
import I18n                    from 'i18next';

const mapStateToProps = (state) => {
    return {
        imagesLeft: _.get(state, 'loading.imagesLeft'),
        isLoading:  _.get(state, 'loading.isLoading'),
        slidesLeft: _.get(state, 'loading.slidesLeft'),
        text:       _.get(state, 'loading.text'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(LoadingActions, dispatch);
};

class LoadingOverlay extends React.Component {
    static propTypes = {
        imagesLeft:     PropTypes.number,
        isLoading:      PropTypes.bool,
        overlayClicked: PropTypes.func,
        slidesLeft:     PropTypes.number,
        text:           PropTypes.string,
    };

    static defaultProps = {
        imagesLeft:     0,
        isLoading:      false,
        overlayClicked: _.noop,
        slidesLeft:     0,
        text:           '',
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    getText = () => {
        const { text, imagesLeft, slidesLeft } = this.props;
        const progressString                   = [
            `${I18n.t('slides')}: `,
            slidesLeft,
            ', ',

            `${I18n.t('images')}: `,
            imagesLeft,
        ].join('');
        const finalText                        = [
            text,
            progressString,
        ].join('\n');

        return finalText;
    };

    loadingOverlayClicked = () => {
        this.props.overlayClicked();
    };

    render() {
        return (
            <StatelessLoadingOverlay
                isLoading={this.props.isLoading}
                loadingOverlayClicked={this.loadingOverlayClicked}
                text={this.getText()}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoadingOverlay);
