//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import ComponentHelper from '@/helper/ComponentHelper';
import classNames      from 'classnames';
import I18n            from 'i18next';
import PropTypes       from '@/components/PropTypes';
import Dropzone        from 'react-dropzone';
import MimeTypes       from '@/constants/MimeTypes';
import _               from 'lodash';
import styles          from './styles.module.scss';

export class GlobalDropZone extends React.Component {
    static defaultStates = {
        dragActive: false,
    };

    static propTypes = {
        children:       PropTypes.children,
        onFileAccepted: PropTypes.func,
    };

    static defaultProps = {
        children:       null,
        onFileAccepted: _.noop,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = Object.keys(this.defaultStates);

    constructor(props) {
        super(props);

        this.state = {
            ...GlobalDropZone.defaultStates,
        };
    }

    onDragEnter = () => {
        console.log('GlobalDropZone: onDragEnter');

        this.setDragActive(true);
    };

    onDragLeave = () => {
        console.log('GlobalDropZone: onDragLeave');

        this.setDragActive(false);
    };

    onDrop = (acceptedFiles) => {
        console.log('GlobalDropZone: onDrop: acceptedFiles', acceptedFiles);

        this.setDragActive(false);

        if (acceptedFiles.length > 0) {
            this.props.onFileAccepted(acceptedFiles[0]);
        }
    };

    render() {
        return (
            <Dropzone
                accept={MimeTypes.pptx}
                onDrop={this.onDrop}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                multiple={false}
                noClick={true}
            >
                {(dropzoneState) => {
                    return (
                        <section
                            className={styles.globalDropZoneWrapper}
                            {...dropzoneState.getRootProps()}
                        >
                            <div
                                className={classNames(
                                    styles.globalDropZone,
                                    {
                                        [styles.globalDropZoneVisible]: this.state.dragActive,
                                    },
                                )}
                            >
                                <div className={styles.globalDropZoneBorder}>
                                    <input
                                        {...dropzoneState.getInputProps()}
                                    />
                                    <section
                                        className={styles.globalDropZoneInnerText}
                                    >
                                        <h3>
                                            {I18n.t('globalDropZoneText1')}
                                        </h3>
                                        <p>
                                            {I18n.t('globalDropZoneText2')}
                                        </p>
                                    </section>
                                </div>
                            </div>
                            {this.props.children}
                        </section>
                    );
                }}
            </Dropzone>
        );
    }

    setDragActive = (dragActive) => {
        this.setState({
            dragActive,
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default GlobalDropZone;
