//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import ComponentHelper from '@/helper/ComponentHelper';
import NumberFormat    from '@/helper/NumberFormat';
import PropTypes       from '@/components/PropTypes';
import I18n            from 'i18next';
import styles          from './styles.module.scss';

export class AnalysisInfo extends React.Component {
    static propTypes = {
        fileSize:            PropTypes.number,
        imageCount:          PropTypes.number,
        imageDuplicateCount: PropTypes.number,
        masterSlideCount:    PropTypes.number,
        slideCount:          PropTypes.number,
    };

    static defaultProps = {
        fileSize:            null,
        imageCount:          null,
        imageDuplicateCount: null,
        masterSlideCount:    null,
        slideCount:          null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <div
                className={styles.analysisInfo}
            >
                {this.renderPart1()}
                {' '}
                <strong>
                    {this.renderPart2()}
                </strong>
                {' '}
                {this.renderPart3()}
                {' '}
                <strong>
                    {this.renderPart4()}
                </strong>
                {' '}
                {this.renderPart5()}
                {' '}
                <strong>
                    {this.renderPart6()}
                </strong>
                {' '}
                {this.renderIdenticalPicturesInfo()}
                {' '}
                {this.renderPart7()}
                {NumberFormat.toHumanReadableFileSize(this.props.fileSize)}
            </div>
        );
    }

    renderPart1 = () => {
        return [
            I18n.t('analyseInfoPart1'),
            this.renderPart1Postfix(),
        ].join(' ');
    };

    renderPart1Postfix = () => {
        const { imageCount } = this.props;

        if (imageCount > 1) {
            return I18n.t(
                'analyseInfoPart1Many',
                {
                    imageCount,
                },
            );
        }

        if (imageCount === 0) {
            return I18n.t('analyseInfoPart1Single');
        }

        return I18n.t('analyseInfoPart1No');
    };

    renderPart2 = () => {
        const { imageCount } = this.props;

        if (imageCount > 1) {
            return I18n.t(
                'analyseInfoPart2Many',
                {
                    imageCount,
                },
            );
        }

        if (imageCount === 0) {
            return I18n.t('analyseInfoPart2Single');
        }

        return I18n.t('analyseInfoPart2No');
    };

    renderPart3 = () => {
        return I18n.t('analyseInfoPart3');
    };

    renderPart4 = () => {
        const { slideCount } = this.props;

        if (slideCount > 1) {
            return I18n.t(
                'analyseInfoPart4Many',
                {
                    slideCount,
                },
            );
        }

        if (slideCount === 0) {
            return I18n.t('analyseInfoPart4Single');
        }

        return I18n.t('analyseInfoPart4No');
    };

    renderPart5 = () => {
        return I18n.t('analyseInfoPart5');
    };

    renderPart6 = () => {
        const { masterSlideCount } = this.props;

        if (masterSlideCount > 1) {
            return I18n.t(
                'analyseInfoPart6Many',
                {
                    masterSlideCount,
                },
            );
        }

        if (masterSlideCount === 0) {
            return I18n.t('analyseInfoPart6Single');
        }

        return I18n.t('analyseInfoPart6No');
    };

    renderPart7 = () => {
        return I18n.t('analyseInfoPart7');
    };

    renderIdenticalPicturesInfo = () => {
        const { imageDuplicateCount, imageCount } = this.props;

        if (imageDuplicateCount > 0) {
            return (
                <span>
                    <strong>
                        {imageDuplicateCount} {I18n.t('outOf')} {imageCount} {I18n.t('images')}
                    </strong>
                    {I18n.t('areIdentical')}
                </span>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default AnalysisInfo;
