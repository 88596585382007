//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }               from 'redux-saga/effects';
import { NavigationActions } from '@/store/actions/navigation';
import Routes                from '@/constants/Routes';
import I18n                  from 'i18next';
import ImageStorage          from '@/helper/ImageStorage';

function* closeFile() {
    ImageStorage.clear();

    yield put(NavigationActions.redirect({
        route: Routes.home,
    }));
}

function* processFileFailed() {
    alert(I18n.t('parseFileError'));
}

function* processFileSucceeded() {
    yield put(NavigationActions.redirect({
        route: Routes.analyse,
    }));
}

export default {
    closeFile,
    processFileFailed,
    processFileSucceeded,
};
