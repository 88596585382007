//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const userAgent          = navigator.userAgent;
const userAgentLowerCase = userAgent.toLowerCase();

class Browser {
    isChrome = userAgentLowerCase.indexOf('chrome') > -1;

    isEdge = userAgentLowerCase.indexOf('edge') > -1;

    isFirefox = userAgentLowerCase.indexOf('firefox') > -1;

    isSafari = userAgentLowerCase.indexOf('safari') > -1;
}

export default Browser;
