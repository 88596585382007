//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class FileUploadButton extends React.Component {
    fileInputReference = null;

    static propTypes = {
        onFileChanged: PropTypes.func,
    };

    static defaultProps = {
        onFileChanged: _.noop,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    onFileChanged = (event) => {
        this.props.onFileChanged(event);

        console.log('this.fileInputReference', this.fileInputReference);

        this.fileInputReference.value = '';
    };

    render() {
        return (
            <input
                className={styles.fileUploadButton}
                onChange={this.onFileChanged}
                ref={this.setFileInputReference}
                type={'file'}
            />
        );
    }

    setFileInputReference = (fileInputReference) => {
        this.fileInputReference = fileInputReference;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default FileUploadButton;
