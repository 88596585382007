//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import '@/language/Language';
import '@babel/polyfill';
import { Provider }        from 'react-redux';
import { PersistGate }     from 'redux-persist/integration/react';
import React               from 'react';
import ReactDOM            from 'react-dom';
import ReactGA             from 'react-ga';
import ReactTooltip        from 'react-tooltip';
import { ConnectedRouter } from 'connected-react-router';
import CacheInvalidator    from '@/components/stateless/atomic/CacheInvalidator';
import GlobalDropZone      from '@/components/connected/GlobalDropZone';
import LightBox            from '@/components/connected/LightBox';
import MainRouter          from '@/components/connected/MainRouter';
import MainMenu            from '@/components/connected/MainMenu';
import LoadingOverlay      from '@/components/connected/LoadingOverlay';
import createStore         from '@/store';
import { history }         from '@/store';
import ImageStorage        from '@/helper/ImageStorage';
import Environment         from '@/helper/Environment';
import styles              from './styles.module.scss';
import * as serviceWorker  from './serviceWorker';

import 'react-image-lightbox/style.css';
import './sentry';
import '@/store/miscellaneous/immutability-helper-extensions';

ImageStorage.initializeStore();

if (Environment.isProduction()) {
    ReactGA.initialize('UA-102739375-2');
}

const { store, persistor } = createStore();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate
            persistor={persistor}
            loading={null}
        >
            <CacheInvalidator />
            <LoadingOverlay />
            <LightBox />
            <GlobalDropZone>
                <ReactTooltip />
                <ConnectedRouter history={history}>
                    <div className={styles.wrapper}>
                        <div className={styles.menuWrapper}>
                            <MainMenu />
                        </div>
                        <div className={styles.contentWrapper}>
                            <MainRouter />
                        </div>
                    </div>
                </ConnectedRouter>
            </GlobalDropZone>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

serviceWorker.register();
