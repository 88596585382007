//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { PropTypes as BasePropTypes } from 'prop-types';
import { TestIdPrefixes }             from '@/constants/TestIds';
import { TestIds }                    from '@/constants/TestIds';

class PropTypes {
    static cssUnits = [
        BasePropTypes.number,
        BasePropTypes.string,
    ];

    static children = BasePropTypes.oneOfType([
        BasePropTypes.arrayOf(BasePropTypes.node),
        BasePropTypes.node,
    ]);

    static cssHeight = BasePropTypes.oneOfType(PropTypes.cssUnits);

    static cssWidth = BasePropTypes.oneOfType(PropTypes.cssUnits);

    static date = BasePropTypes.oneOfType([
        BasePropTypes.string,
        BasePropTypes.instanceOf(Date),
        BasePropTypes.number,
    ]);

    static image = BasePropTypes.oneOf([
        BasePropTypes.string,
        BasePropTypes.object,
    ]);

    static numberOrString = BasePropTypes.oneOfType([
        BasePropTypes.string,
        BasePropTypes.number,
    ]);

    static oneOfObjectKeys = (object) => {
        return BasePropTypes.oneOf(Object.keys(object));
    };

    static oneOfObjectValues = (object) => {
        return BasePropTypes.oneOf(Object.values(object));
    };

    static oneOfTestIds = this.oneOfObjectValues(TestIds);

    static oneOfTestIdPrefix = this.oneOfObjectValues(TestIdPrefixes);
}

export default Object.assign(PropTypes, BasePropTypes);
