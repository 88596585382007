//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import ComponentHelper  from '@/helper/ComponentHelper';
import I18n             from 'i18next';
import FileUploadButton from '@/components/connected/FileUploadButton';
import styles           from './styles.module.scss';

export class DropZone extends React.Component {
    static propTypes = {};

    static defaultProps = {};

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <div className={styles.dropZone}>
                <h2>
                    {I18n.t('dropZoneText1')}
                    <br />
                    {I18n.t('dropZoneText2')}
                </h2>
                <FileUploadButton />
                <span
                    className={styles.arrow}
                    aria-hidden="true"
                />
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default DropZone;
