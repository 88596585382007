//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

const LoadingTypes = Object.freeze({
    OVERLAY_CLICKED:   'Loading/OVERLAY_CLICKED',
    RESET_OVERLAY:     'Loading/RESET_OVERLAY',
    SET_PROGRESS_LEFT: 'Loading/SET_PROGRESS_LEFT',
});

const overlayClicked = makeActionCreator(
    LoadingTypes.OVERLAY_CLICKED,
    {},
);

const resetOverlay = makeActionCreator(
    LoadingTypes.RESET_OVERLAY,
    {},
);

const setProgressLeft = makeActionCreator(
    LoadingTypes.SET_PROGRESS_LEFT,
    {
        imagesLeft: null,
        slidesLeft: null,
    },
);

const LoadingActions = Object.freeze({
    overlayClicked,
    resetOverlay,
    setProgressLeft,
});

export {
    LoadingActions,
    LoadingTypes,
};
