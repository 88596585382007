//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { Route }       from 'react-router';
import { Switch }      from 'react-router';
import { connect }     from 'react-redux';
import ComponentHelper from '@/helper/ComponentHelper';
import Analyse         from '@/screens/Analyse';
import Home            from '@/screens/Home';
import Help            from '@/screens/Help';
import Imprint         from '@/screens/Imprint';
import NotFound        from '@/screens/NotFound';
import Routes          from '@/constants/Routes';
import DataProtection  from '@/screens/DataProtection';

const mapStateToProps = (state) => (
    {}
);

class MainRouter extends React.Component {
    static propTypes = {};

    static defaultProps = {};

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <Switch>
                <Route
                    exact
                    path={Routes.home}
                    component={Home}
                />
                <Route
                    exact
                    path={Routes.analyse}
                    component={Analyse}
                />
                <Route
                    exact
                    path={Routes.help}
                    component={Help}
                />
                <Route
                    exact
                    path={Routes.dataProtection}
                    component={DataProtection}
                />
                <Route
                    exact
                    path={Routes.imprint}
                    component={Imprint}
                />
                <Route component={NotFound} />
            </Switch>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default connect(
    mapStateToProps,
    null,
)(MainRouter);
