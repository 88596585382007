//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

const LightBoxTypes = Object.freeze({
    CLOSE:          'LightBoxTypes/CLOSE',
    NEXT_IMAGE:     'LightBoxTypes/NEXT_IMAGE',
    PREVIOUS_IMAGE: 'LightBoxTypes/PREVIOUS_IMAGE',
    OPEN:           'LightBoxTypes/OPEN',
    OPEN_WITH_STORE_ID:  'LightBoxTypes/OPEN_WITH_STORE_ID',
});

const close = makeActionCreator(
    LightBoxTypes.CLOSE,
    {},
);

const open = makeActionCreator(
    LightBoxTypes.OPEN,
    {},
);

const openWithStoreId = makeActionCreator(
    LightBoxTypes.OPEN_WITH_STORE_ID,
    {
        storeId: null,
    },
);

const LightBoxActions = Object.freeze({
    close,
    open,
    openWithStoreId,
});

export {
    LightBoxTypes,
    LightBoxActions,
};
