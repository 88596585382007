//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n                from 'i18next';
import React               from 'react';
import Spacer              from '@/components/stateless/atomic/Spacer';
import Paragraph           from '@/components/stateless/atomic/Paragraph';
import LongText            from '@/components/stateless/atomic/LongText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple }         from '@fortawesome/free-brands-svg-icons';
import { faChrome }        from '@fortawesome/free-brands-svg-icons';
import { faFirefox }       from '@fortawesome/free-brands-svg-icons';
import { faSafari }        from '@fortawesome/free-brands-svg-icons';
import { faEdge }          from '@fortawesome/free-brands-svg-icons';
import { faWindows }       from '@fortawesome/free-brands-svg-icons';
import PageTitle           from '@/components/stateless/composed/PageTitle';

export default class Imprint extends React.Component {
    render() {
        return (
            <>
                <PageTitle
                    title={I18n.t('help')}
                />
                <LongText>
                    <h3>
                        Ihre Präsentation bleibt auf Ihrem Computer
                    </h3>
                    <Paragraph fullWidth={false}>
                        Dieses Tool arbeitet komplett browserbasiert und lädt keine Daten in das Internet hoch. Es werden lediglich
                        Technologien lokal auf Ihrem Computer ausgeführt, um Ihre PowerPoint-Präsentation zu überprüfen.
                    </Paragraph>
                    <Spacer height={20} />
                    <h3>
                        Browserkompatibilität
                    </h3>
                    <Paragraph fullWidth={false}>
                        Der chartwerker® ist mit allen gängigen, modernen Web-Browsern kompatibel.
                        Für die beste Nutzererfahrung empfehlen wir
                        {' '}
                        <a
                            href={'https://www.google.de/chrome/browser/desktop/'}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            <FontAwesomeIcon icon={faChrome} />
                            {' '}
                            Google Chrome
                        </a>
                    </Paragraph>
                    <Paragraph>
                        Erfolgreich getestet haben wir den
                        chartwerker® auf folgenden Plattformen:
                    </Paragraph>
                    <ul>
                        <li>
                            <a
                                href={'https://www.google.de/chrome/browser/desktop/'}
                                target={'_blank'}
                                rel={'noreferrer'}
                            >
                                <FontAwesomeIcon icon={faChrome} />
                                {' '}
                                Google Chrome
                            </a>
                            {' '}
                            Version 59
                            auf
                            {' '}
                            <FontAwesomeIcon icon={faApple} />
                            {' '}
                            macOS Sierra 10.12.5
                        </li>
                        <li>
                            <a
                                href={'https://www.mozilla.org/de/firefox/'}
                                target={'_blank'}
                                rel={'noreferrer'}
                            >
                                <FontAwesomeIcon icon={faFirefox} />
                                {' '}
                                Mozilla Firefox
                            </a>
                            {' '}
                            Version 54
                            auf
                            {' '}
                            <FontAwesomeIcon icon={faApple} />
                            {' '}
                            macOS Sierra 10.12.5
                        </li>
                        <li>
                            <a
                                href="https://support.apple.com/de_DE/downloads/safari"
                                target={'_blank'}
                                rel={'noreferrer'}
                            >
                                <FontAwesomeIcon icon={faSafari} />
                                {' '}
                                Apple Safari
                            </a>
                            {' '}
                            Version 10.1.1
                            auf
                            {' '}
                            <FontAwesomeIcon icon={faApple} />
                            {' '}
                            macOS Sierra 10.12.5
                        </li>
                        <li>
                            <a
                                href={'https://www.google.de/chrome/browser/desktop/'}
                                target={'_blank'}
                                rel={'noreferrer'}
                            >
                                <FontAwesomeIcon icon={faChrome} />
                                {' '}
                                Google Chrome
                            </a>
                            {' '}
                            Version 59
                            auf
                            {' '}
                            <FontAwesomeIcon icon={faWindows} />
                            {' '}
                            Windows 10 (1607)
                        </li>
                        <li>
                            <a
                                href={'https://www.microsoft.com/de-de/windows/microsoft-edge'}
                                target={'_blank'}
                                rel={'noreferrer'}
                            >
                                <FontAwesomeIcon icon={faEdge} />
                                {' '}
                                Microsoft Edge
                            </a>
                            {' '}
                            Version 38
                            auf
                            {' '}
                            <FontAwesomeIcon icon={faWindows} />
                            {' '}
                            Windows 10 (1607)
                        </li>
                        <li>
                            <a
                                href={'https://support.apple.com/de_DE/downloads/safari'}
                                target={'_blank'}
                                rel={'noreferrer'}
                            >
                                <FontAwesomeIcon icon={faSafari} />
                                {' '}
                                Apple Safari
                            </a>
                            {' '}
                            Version 10.3.3
                            auf
                            {' '}
                            <FontAwesomeIcon icon={faApple} />
                            {' '}
                            iOS 10.3.3 (14G60)
                        </li>
                    </ul>
                </LongText>
            </>
        );
    }
}
