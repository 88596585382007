//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n            from 'i18next';
import React           from 'react';
import VersionNumber   from '@/components/stateless/atomic/VersionNumber';
import Spacer          from '@/components/stateless/atomic/Spacer';
import LongText        from '@/components/stateless/atomic/LongText';
import Paragraph       from '@/components/stateless/atomic/Paragraph';
import MultiColumnText from '@/components/stateless/atomic/MultiColumnText';
import PageTitle       from '@/components/stateless/composed/PageTitle';

export default class Imprint extends React.Component {
    render() {
        return (
            <>
                <PageTitle
                    title={I18n.t('imprint')}
                />
                <LongText>
                    <h3>
                        chartwerk
                        <sup>
                            ®
                        </sup>
                        {' '}
                        Präsentationen GbR · Corinna Bende und Susanne Strauch
                    </h3>
                    <Paragraph>
                        <strong>
                            chartwerk
                            <sup>
                                ®
                            </sup>
                            {' '}
                            Präsentationen GbR
                        </strong>
                        <br />
                        Wollankstraße 108
                        <br />
                        13187 Berlin
                        <br />
                        <br />
                        <strong>
                            vertreten durch
                        </strong>
                        {' '}
                        Corinna Bende und Susanne Strauch
                    </Paragraph>
                    <Paragraph>
                        <strong>
                            Tel.
                        </strong>
                        {' '}
                        +49 30 695412-30
                        <br />
                        <strong>
                            Fax
                        </strong>
                        {' '}
                        +49 30 69 5412-31
                        <br />
                        <a
                            href={`mailto:${I18n.t('chartwerkMail')}`}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            {I18n.t('chartwerkMail')}
                        </a>
                        <br />
                        <a
                            href={I18n.t('chartwerkWebsite')}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            {I18n.t('chartwerkWebsite')}
                        </a>
                    </Paragraph>
                    <Paragraph>
                        <strong>
                            Verantwortlich für den Inhalt
                        </strong>
                        {' '}
                        Corinna Bende und Susanne Strauch
                        <br />
                        <strong>
                            Steuernr.
                        </strong>
                        {' '}
                        35/224/00919
                    </Paragraph>
                    <Paragraph>
                        © Copyright chartwerk
                        <sup>
                            ®
                        </sup>
                        {' '}
                        Präsentationen GbR
                        <br />
                        Bilder chartwerk
                        <sup>
                            ®
                        </sup>
                    </Paragraph>
                    <Spacer height={20} />
                    <h4>
                        Disclaimer – rechtliche Hinweise
                    </h4>
                    <MultiColumnText>
                        <Paragraph>
                            § 1 Haftungsbeschränkung

                            Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt. Der
                            Anbieter übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit
                            und Aktualität der bereitgestellten Inhalte. Die Nutzung der Inhalte der
                            Website erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete
                            Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung
                            des Anbieters wieder. Mit der reinen Nutzung der Website des Anbieters kommt
                            keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.
                        </Paragraph>
                        <Paragraph>
                            § 2 Externe Links

                            Diese Website enthält Verknüpfungen zu Websites Dritter (&quot;externe Links&quot;).
                            Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der
                            Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden
                            Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem
                            Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei
                            Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der
                            verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich
                            der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen
                            macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne
                            konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von
                            Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.
                        </Paragraph>
                        <Paragraph>
                            § 3 Urheber- und Leistungsschutzrechte

                            Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen
                            Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und
                            Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen
                            schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies
                            gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung,
                            Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken
                            oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter
                            sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder
                            Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
                            strafbar. Lediglich die Herstellung von Kopien und Downloads für den
                            persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.
                            <br />
                            Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher
                            Erlaubnis zulässig.
                        </Paragraph>
                        <Paragraph>
                            § 4 Besondere Nutzungsbedingungen

                            Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den
                            vorgenannten Paragraphen abweichen, wird an entsprechender Stelle
                            ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen
                            Einzelfall die besonderen Nutzungsbedingungen.
                        </Paragraph>
                    </MultiColumnText>
                    <Spacer height={20} />
                    <h4>
                        Entwicklung
                    </h4>
                    <Paragraph fullWidth={false}>
                        Dieses Tool wurde von
                        {' '}
                        <strong>
                            Lulububu Software GmbH
                        </strong>
                        {' '}
                        im Auftrag der
                        {' '}
                        <strong>
                            chartwerk
                            <sup>
                                ®
                            </sup>
                            {' '}
                            Präsentationen GbR
                        </strong>
                        {' '}
                        entwickelt. Lulububu ist ein
                        {' '}
                        <a
                            href={I18n.t('lulububuLink')}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            App-Entwickler aus Ravensburg
                        </a>
                        {' '}
                        und bietet neben iOS- sowie Android-Apps auch Webseiten und browserbasierte Anwendungen.
                    </Paragraph>
                    <Paragraph>
                        <a
                            href={I18n.t('lulububuLink')}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            {I18n.t('lulububuLink')}
                        </a>
                    </Paragraph>
                    <Spacer height={20} />
                    <h4>
                        Version
                    </h4>
                    <VersionNumber />
                </LongText>
            </>
        );
    }
}
