//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                      from 'immutability-helper';
import { PresentationParserTypes } from '@/store/actions/presentationParser';

const initialState = Object.freeze({
    images:              [],
    imageSizeSum:        0,
    fileSize:            0,
    masterSlides:        0,
    slides:              0,
    fileName:            '',
    pictureStateOnSlide: '',
});

const closeFile = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const processFileSucceeded = (action, state) => {
    const { dataBuffer } = action;

    return update(state, {
        images:                   {
            $set: [...dataBuffer.images],
        },
        imageSizeSum:             {
            $set: dataBuffer.imageSizeSum,
        },
        totalImageCount:          {
            $set: dataBuffer.totalImageCount,
        },
        totalDuplicateImageCount: {
            $set: dataBuffer.totalDuplicateImageCount,
        },
        fileName:                 {
            $set: dataBuffer.fileName,
        },
        fileSize:                 {
            $set: dataBuffer.fileSize,
        },
        masterSlides:             {
            $set: dataBuffer.masterSlides,
        },
        slides:                   {
            $set: dataBuffer.slides,
        },
        pictureStateOnSlide:      {
            $set: dataBuffer.pictureStateOnSlide,
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case PresentationParserTypes.CLOSE_FILE:             return closeFile(action, state);
        case PresentationParserTypes.PROCESS_FILE_SUCCEEDED: return processFileSucceeded(action, state);
        default:                                             return state;
        // @formatter:on
    }
}
