//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                             from 'lodash';
import createSagaMiddleware          from 'redux-saga';
import storage                       from 'redux-persist/lib/storage';
import withReady                     from 'redux-ready';
import { applyMiddleware }           from 'redux';
import { compose }                   from 'redux';
import { createStore }               from 'redux';
import { createBrowserHistory }      from 'history';
import { createStateSyncMiddleware } from 'redux-state-sync';
import { initMessageListener }       from 'redux-state-sync';
import { persistReducer }            from 'redux-persist';
import { persistStore }              from 'redux-persist';
import { routerMiddleware }          from 'connected-react-router';
import Environment                   from '@/helper/Environment';
import sagas                         from './sagas/index';
import rootReducer                   from './reducer';
import presentationParserMiddleware  from './miscellaneous/presentationParser';

const history                             = createBrowserHistory();
const sagaMiddleware                      = createSagaMiddleware();
const presentationParserMiddlewareService = presentationParserMiddleware();

const persistConfig    = {
    key:       'root',
    storage,
    blacklist: [
        'loading',
        'router',
    ],
};
const persistedReducer = persistReducer(
    persistConfig,
    rootReducer(history),
);
const stateSyncConfig  = {
    whitelist: [],
};

export {
    history,
    sagaMiddleware,
    presentationParserMiddlewareService,
};

export default (initialState) => {
    const isDevelopment    = Environment.isDevelopment();
    const allMiddlewares   = [
        (
            isDevelopment ?
                // This is inline since we only want to load this module in development mode
                // eslint-disable-next-line global-require
                require('redux-immutable-state-invariant').default() :
                null
        ),
        presentationParserMiddlewareService,
        sagaMiddleware,
        routerMiddleware(history),
        createStateSyncMiddleware(stateSyncConfig),
    ];
    const middlewares      = _.reject(allMiddlewares, _.isNull);
    // eslint-disable-next-line no-underscore-dangle
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
    const store            = createStore(
        persistedReducer,
        initialState,
        composeEnhancers(
            withReady,
            applyMiddleware(...middlewares),
        ),
    );

    store.ready().then(() => {
        sagaMiddleware.run(sagas.root);
    });

    initMessageListener(store);
    const persistor = persistStore(store);

    return {
        store,
        persistor,
    };
};
