//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import { Helmet }      from 'react-helmet';
import PropTypes       from '@/components/PropTypes';
import PageTitleHelper from '@/helper/PageTitle';
import ComponentHelper from '@/helper/ComponentHelper';

/**
 * If this component is missing features, you have to use <Helmet> on your own since
 * this is only shortcut for:
 *
 * <Helmet>
 *     <title>
 *         {PageTitleHelper.getPageTitle(I18n.t('example'))}
 *     </title>
 * </Helmet>
 */
export default class PageTitle extends React.Component {
    static propTypes = {
        title: PropTypes.string,
    };

    static defaultProps = {
        title: _.noop,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <Helmet>
                <title>
                    {PageTitleHelper.getPageTitle(this.props.title)}
                </title>
            </Helmet>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}
