//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import I18n      from 'i18next';
import PageTitle from '@/components/stateless/composed/PageTitle';
import Paragraph from '@/components/stateless/atomic/Paragraph';
import LongText  from '@/components/stateless/atomic/LongText';
import Link      from '@/components/stateless/atomic/Link';
import Routes    from '@/constants/Routes';

export default class NotFound extends React.Component {
    render() {
        return (
            <LongText>
                <PageTitle
                    title={I18n.t('pageNotFound')}
                />
                <h3>
                    {I18n.t('error404')}
                </h3>
                <Paragraph>
                    {I18n.t('pageNotFound')}
                </Paragraph>
                <Paragraph>
                    <Link
                        to={Routes.home}
                    >
                        {I18n.t('backToHomePage')}
                    </Link>
                </Paragraph>
            </LongText>
        );
    }
}
