//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { combineReducers }       from 'redux';
import { connectRouter }         from 'connected-react-router';
import { withReduxStateSync }    from 'redux-state-sync';
import loadingReducer            from './loading';
import lightBoxReducer           from './lightBox';
import presentationParserReducer from './presentationParser';

export default (history) => withReduxStateSync(combineReducers({
    loading:            loadingReducer,
    lightBox:           lightBoxReducer,
    presentationParser: presentationParserReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
}));
