//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import { connect }            from 'react-redux';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';
import ComponentHelper        from '@/helper/ComponentHelper';
import PropTypes              from '@/components/PropTypes';
import ImageStorage           from '@/helper/ImageStorage';
import Lightbox               from 'react-image-lightbox';
import { LightBoxActions }    from '@/store/actions/lightBox';

const mapStateToProps = (state) => {
    return {
        storeId:      _.get(state, 'lightBox.storeId', 0),
        showLightBox: _.get(state, 'lightBox.open'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(LightBoxActions, dispatch);
};

class LightBox extends React.Component {
    base64ImageUrls = [];

    static propTypes = {
        showLightBox: PropTypes.bool,
        storeId:      PropTypes.number,
        close:        PropTypes.func,
    };

    static defaultProps = {
        showLightBox: false,
        storeId:      0,
        close:        _.noop,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [
        'imageData',
    ];

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { storeId } = this.props;

        if (prevProps.storeId !== storeId) {
            ImageStorage.loadImage(storeId).then((imageData) => {
                this.setState({
                    imageData: imageData.image,
                });
            });
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            imageData: null,
        };
    }

    onCloseRequest = () => {
        this.props.close();
    };

    render() {
        const { showLightBox } = this.props;

        if (showLightBox) {
            return (
                <Lightbox
                    mainSrc={this.state.imageData}
                    onCloseRequest={this.onCloseRequest}
                />
            );
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LightBox);
