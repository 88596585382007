const Buffer = require('buffer').Buffer;
const Icnov = require('iconv-lite');

const WMFConverter = function() {
    this.executeTime = 0;
};

WMFConverter.prototype.toCanvas = function(buffer, canvas, callback) {
    parseWMF(buffer, canvas);

    if (typeof (
        callback
    ) === 'function') {
        callback(this);
    }

    function UserException(message) {
        this.message = message;
        this.name = 'UserException';
    }

    function insertObjToFirstNull(arr, obj) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === null) {
                arr[i] = obj;

                break;
            }
        }
    }

    function drawBmpImage(ctx, base64ImgData, sx, sy, sw, sh, dx, dy, dw, dh, rop) {
        // TODO: Not implement rop
        const img = new Image();
        img.src = `data:image/bmp;base64,${base64ImgData}`;
        img.onload = function() {
            ctx.drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh);
        };
        img.onerror = function(stuff) {

        };
    }

    function drawPie(ctx, sxr, syr, exr, eyr, sxa, sya, exa, eya) {
        const rx = Math.abs(exr - sxr) / 2.0;
        const ry = Math.abs(eyr - syr) / 2.0;

        if (rx <= 0 || ry <= 0) {
            return;
        }

        const cx = Math.min(sxr, exr) + rx;
        const cy = Math.min(syr, eyr) + ry;

        ctx.beginPath();

        if (sxa == exa && sya == eya) {
            // Non-Rotate
            if (rx == ry) {
                ctx.arc(cx, cy, rx, 0, 2 * Math.PI);
            } else {
                ctx.ellipse(cx, cy, rx, ry, 0, 0, 2 * Math.PI);
            }
        } else {
            // Rotate
            const sa = Math.atan2(
                (
                    sya - cy
                ) * rx,
                (
                    sxa - cx
                ) * ry,
            );
            const sx = rx * Math.cos(sa);
            const sy = ry * Math.sin(sa);
            const ea = Math.atan2(
                (
                    eya - cy
                ) * rx,
                (
                    exa - cx
                ) * ry,
            );
            const ex = rx * Math.cos(ea);
            const ey = ry * Math.sin(ea);
            const a = Math.atan2(
                (
                    ex - sx
                ) * (
                    -sy
                ) - (
                    ey - sy
                ) * (
                    -sx
                ),
                (
                    ex - sx
                ) * (
                    -sx
                ) + (
                    ey - sy
                ) * (
                    -sy
                ),
            );

            ctx.ellipse(cx, cy, rx, ry, a, 0, 2 * Math.PI);
        }

        ctx.stroke();
    }

    function drawRoundRect(ctx, x, y, width, height, radius, fill, stroke) {
        if (typeof stroke === 'undefined') {
            stroke = true;
        }

        if (typeof radius === 'undefined') {
            radius = 5;
        }

        if (typeof radius === 'number') {
            radius = {
                tl: radius,
                tr: radius,
                br: radius,
                bl: radius,
            };
        } else {
            const defaultRadius = {
                tl: 0,
                tr: 0,
                br: 0,
                bl: 0,
            };

            for (const side in defaultRadius) {
                radius[side] = radius[side] || defaultRadius[side];
            }
        }
        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
        if (fill) {
            ctx.fill();
        }

        if (stroke) {
            ctx.stroke();
        }
    }

    function Int32ToHexColor(color) {
        const blue = (
            color >> 16
        ) & 0xFF;
        const green = (
            color >> 8
        ) & 0xFF;
        const red = color & 0xFF;

        return sprintf(
            '#%06X',
            (
                red << 16
            ) | (
                green << 8
            ) | blue,
        );
    }

    function Uint8ArrayToBase64(dv) {
        let bs = '';

        for (let i = 0; i < dv.byteLength; i++) {
            bs += String.fromCharCode(dv.getUint8(i));
        }
        return window.btoa(bs);
    }

    function dibToBmp(dib) {
        const length = dib.length;
        // dibToBmp
        const bmpData = new DataView(new ArrayBuffer(length + 14));

        /* BitmapFileHeader */
        bmpData.setUint8(0, 0x42);
        bmpData.setUint8(1, 0x4D);
        bmpData.setUint8(2, length & 0xff);
        bmpData.setUint8(
            3,
            (
                length >> 8
            ) & 0xff,
        );
        bmpData.setUint8(
            4,
            (
                length >> 16
            ) & 0xff,
        );
        bmpData.setUint8(
            5,
            (
                length >> 24
            ) & 0xff,
        );

        // reserved 1
        bmpData.setUint8(6, 0x00);
        bmpData.setUint8(7, 0x00);

        // reserved 2
        bmpData.setUint8(8, 0x00);
        bmpData.setUint8(9, 0x00);

        /* BitmapInfoHeader */
        const biSize = (
            dib[0] & 0xFF
        ) + (
            (
                dib[1] & 0xFF
            ) << 8
        )
            + (
                (
                    dib[2] & 0xFF
                ) << 16
            ) + (
            (
                dib[3] & 0xFF
            ) << 24
        );
        // offset
        let bfOffBits = biSize + 14;
        const biBitCount = (
            dib[14] & 0xFF
        ) + (
            (
                dib[15] & 0xFF
            ) << 8
        );
        const clrUsed = (
            dib[32] & 0xFF
        ) + (
            (
                dib[33] & 0xFF
            ) << 8
        )
            + (
                (
                    dib[34] & 0xFF
                ) << 16
            ) + (
            (
                dib[35] & 0xFF
            ) << 24
        );

        switch (biBitCount) {
            case 1:
                bfOffBits += (
                    0x1 + 1
                ) * 4;

                break;
            case 4:
                bfOffBits += (
                    0xF + 1
                ) * 4;

                break;
            case 8:
                bfOffBits += (
                    0xFF + 1
                ) * 4;

                break;
            case 16:
                bfOffBits += (
                    clrUsed == 0
                ) ? 0 : (
                        0xFFFF + 1
                    ) * 4;

                break;
            case 24:
                bfOffBits += (
                    clrUsed == 0
                ) ? 0 : (
                        0xFFFFFF + 1
                    ) * 4;

                break;
            case 32:
                bfOffBits += (
                    clrUsed == 0
                ) ? 0 : (
                        0xFFFFFFFF + 1
                    ) * 4;

                break;
        }

        bmpData.setUint8(10, bfOffBits & 0xFF);
        bmpData.setUint8(
            11,
            (
                bfOffBits >> 8
            ) & 0xFF,
        );
        bmpData.setUint8(
            12,
            (
                bfOffBits >> 16
            ) & 0xFF,
        );
        bmpData.setUint8(
            13,
            (
                bfOffBits >> 24
            ) & 0xFF,
        );

        // Copy dib data
        for (let i = 0; i < length; i++) {
            bmpData.setUint8(14 + i, dib[i]);
        }

        return bmpData;
    }

    function getCharset(charset) {
        switch (charset) {
            case   0:
                return 'CP1252';
            case   2:
                return 'CP1252';
            case  77:
                return 'MacRoman';
            case 128:
                return 'CP932';
            case 129:
                return 'CP949';
            case 130:
                return 'Johab';
            case 134:
                return 'CP936';
            case 136:
                return 'big5';
            case 161:
                return 'CP1253';
            case 162:
                return 'CP1254';
            case 163:
                return 'CP1258';
            case 177:
                return 'CP1255';
            case 178:
                return 'CP1256';
            case 186:
                return 'CP1257';
            case 204:
                return 'CP1251';
            case 222:
                return 'CP874';
            case 238:
                return 'CP1250';
            case 255:
                return 'CP1252';
            default:
                return 'CP1252';
        }
    }

    function toAbsoluteX(x, ww, wx, mx, wox, wsx) {
        return (
            (
                ww >= 0
            ) ? 1 : -1
        ) * (
            mx * x - (
                wx + wox
            )
        ) / wsx;
    }

    function toAbsoluteY(y, wh, wy, my, woy, wsy) {
        return (
            (
                wh >= 0
            ) ? 1 : -1
        ) * (
            my * y - (
                wy + woy
            )
        ) / wsy;
    }

    function toRelativeX(x, ww, mx, wsx) {
        return (
            (
                ww >= 0
            ) ? 1 : -1
        ) * (
            mx * x
        ) / wsx;
    }

    function toRelativeY(y, wh, my, wsy) {
        return (
            (
                wh >= 0
            ) ? 1 : -1
        ) * (
            my * y
        ) / wsy;
    }

    function parseWMF(dv, canvas) {
        const RECORD_EOF = 0x0000;
        const RECORD_REALIZE_PALETTE = 0x0035;
        const RECORD_SET_PALETTE_ENTRIES = 0x0037;
        const RECORD_SET_BK_MODE = 0x0102;
        const RECORD_SET_MAP_MODE = 0x0103;
        const RECORD_SET_ROP2 = 0x0104;
        const RECORD_SET_REL_ABS = 0x0105;
        const RECORD_SET_POLY_FILL_MODE = 0x0106;
        const RECORD_SET_STRETCH_BLT_MODE = 0x0107;
        const RECORD_SET_TEXT_CHARACTER_EXTRA = 0x0108;
        const RECORD_RESTORE_DC = 0x0127;
        const RECORD_RESIZE_PALETTE = 0x0139;
        const RECORD_DIB_CREATE_PATTERN_BRUSH = 0x0142;
        const RECORD_SET_LAYOUT = 0x0149;
        const RECORD_SET_BK_COLOR = 0x0201;
        const RECORD_SET_TEXT_COLOR = 0x0209;
        const RECORD_OFFSET_VIEWPORT_ORG_EX = 0x0211;
        const RECORD_LINE_TO = 0x0213;
        const RECORD_MOVE_TO_EX = 0x0214;
        const RECORD_OFFSET_CLIP_RGN = 0x0220;
        const RECORD_FILL_RGN = 0x0228;
        const RECORD_SET_MAPPER_FLAGS = 0x0231;
        const RECORD_SELECT_PALETTE = 0x0234;
        const RECORD_POLYGON = 0x0324;
        const RECORD_POLYLINE = 0x0325;
        const RECORD_SET_TEXT_JUSTIFICATION = 0x020A;
        const RECORD_SET_WINDOW_ORG_EX = 0x020B;
        const RECORD_SET_WINDOW_EXT_EX = 0x020C;
        const RECORD_SET_VIEWPORT_ORG_EX = 0x020D;
        const RECORD_SET_VIEWPORT_EXT_EX = 0x020E;
        const RECORD_OFFSET_WINDOW_ORG_EX = 0x020F;
        const RECORD_SCALE_WINDOW_EXT_EX = 0x0410;
        const RECORD_SCALE_VIEWPORT_EXT_EX = 0x0412;
        const RECORD_EXCLUDE_CLIP_RECT = 0x0415;
        const RECORD_INTERSECT_CLIP_RECT = 0x0416;
        const RECORD_ELLIPSE = 0x0418;
        const RECORD_FLOOD_FILL = 0x0419;
        const RECORD_FRAME_RGN = 0x0429;
        const RECORD_ANIMATE_PALETTE = 0x0436;
        const RECORD_TEXT_OUT = 0x0521;
        const RECORD_POLY_POLYGON = 0x0538;
        const RECORD_EXT_FLOOD_FILL = 0x0548;
        const RECORD_RECTANGLE = 0x041B;
        const RECORD_SET_PIXEL = 0x041F;
        const RECORD_ROUND_RECT = 0x061C;
        const RECORD_PAT_BLT = 0x061D;
        const RECORD_SAVE_DC = 0x001E;
        const RECORD_PIE = 0x081A;
        const RECORD_STRETCH_BLT = 0x0B23;
        const RECORD_ESCAPE = 0x0626;
        const RECORD_INVERT_RGN = 0x012A;
        const RECORD_PAINT_RGN = 0x012B;
        const RECORD_SELECT_CLIP_RGN = 0x012C;
        const RECORD_SELECT_OBJECT = 0x012D;
        const RECORD_SET_TEXT_ALIGN = 0x012E;
        const RECORD_ARC = 0x0817;
        const RECORD_CHORD = 0x0830;
        const RECORD_BIT_BLT = 0x0922;
        const RECORD_EXT_TEXT_OUT = 0x0a32;
        const RECORD_SET_DIBITS_TO_DEVICE = 0x0d33;
        const RECORD_DIB_BIT_BLT = 0x0940;
        const RECORD_DIB_STRETCH_BLT = 0x0b41;
        const RECORD_STRETCH_DIBITS = 0x0f43;
        const RECORD_DELETE_OBJECT = 0x01f0;
        const RECORD_CREATE_PALETTE = 0x00f7;
        const RECORD_CREATE_PATTERN_BRUSH = 0x01F9;
        const RECORD_CREATE_PEN_INDIRECT = 0x02FA;
        const RECORD_CREATE_FONT_INDIRECT = 0x02FB;
        const RECORD_CREATE_BRUSH_INDIRECT = 0x02FC;
        const RECORD_CREATE_RECT_RGN = 0x06FF;

        canvas.width = 480;
        canvas.height = 320;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        let offset = 0; let
            offset_bk = 0;
        let mtType = 0; let
            mtHeaderSize = 0;
        // DC window
        let wx = 0; let wy = 0; let ww = 0; let
            wh = 0;
        // DC window offset
        const wox = 0; const
            woy = 0;
        // DC window scale
        let wsx = 1.0; let
            wsy = 1.0;
        // DC mapping scale
        const mx = 1.0; const
            my = 1.0;
        let charset = 0;
        let textColor = '#000';
        let fillMode = 'evenodd';
        const key = dv.getUint32(offset, true);
        offset += 4;
        if (key == 0x9AC6CDD7) {
            // 可視區域
            const hmf = dv.getInt16(offset, true);
            offset += 2;
            const vsx = dv.getInt16(offset, true);
            offset += 2;
            const vsy = dv.getInt16(offset, true);
            offset += 2;
            const vex = dv.getInt16(offset, true);
            offset += 2;
            const vey = dv.getInt16(offset, true);
            offset += 2;
            const dpi = dv.getUint16(offset, true);
            offset += 2;
            const reserved = dv.getUint32(offset, true);
            offset += 4;
            const checksum = dv.getUint16(offset, true);
            offset += 2;

            // gdi.placeableHeader(vsx, vsy, vex, vey, dpi);

            mtType = dv.getUint16(offset, true);
            offset += 2;
            mtHeaderSize = dv.getUint16(offset, true);
            offset += 2;
        } else {
            mtType = (
                key & 0x0000FFFF
            );
            mtHeaderSize = (
                (
                    key & 0xFFFF0000
                ) >> 16
            );
        }

        const mtVersion = dv.getUint16(offset, true);
        offset += 2;
        const mtSize = dv.getUint32(offset, true);
        offset += 4;
        const mtNoObjects = dv.getUint16(offset, true);
        offset += 2;
        const mtMaxRecord = dv.getUint32(offset, true);
        offset += 4;
        const mtNoParameters = dv.getUint16(offset, true);
        offset += 2;

        if (mtType != 1 || mtHeaderSize != 9) {
            // throw new UserException("Invalid file format.");
        }

        const objs = new Array(mtNoObjects);

        for (var i = 0; i < mtNoObjects; i++) {
            objs[i] = null;
        }

        while (true) {
            const size = dv.getUint32(offset, true) - 3;
            offset += 4;
            const id = dv.getUint16(offset, true);
            offset += 2;

            if (id == RECORD_EOF) {
                break; // Last record
            }

            offset_bk = offset;

            switch (id) {
                case RECORD_REALIZE_PALETTE: {
                    // gdi.realizePalette();

                    break;
                }
                case RECORD_SET_PALETTE_ENTRIES: {
                    var entries = new Array[dv.getUint16(offset, true)]();
                    offset += 2;
                    var startIndex = dv.getUint16(offset, true);
                    offset += 2;
                    var objID = dv.getUint16(offset, true);
                    offset += 2;
                    for (var i = 0; i < entries.length; i++) {
                        entries[i] = dv.getInt32(offset, true);
                        offset += 4;
                    }
                    // gdi.setPaletteEntries((//gdiPalette) objs[objID], startIndex, entries);

                    break;
                }
                case RECORD_SET_BK_MODE: {
                    var mode = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.setBkMode(mode);

                    break;
                }
                case RECORD_SET_MAP_MODE: {
                    var mode = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.setMapMode(mode);

                    break;
                }
                case RECORD_SET_ROP2: {
                    var mode = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.setROP2(mode);

                    break;
                }
                case RECORD_SET_REL_ABS: {
                    var mode = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.setRelAbs(mode);

                    break;
                }
                case RECORD_SET_POLY_FILL_MODE: {
                    var mode = dv.getInt16(offset, true);
                    offset += 2;
                    fillMode = (
                        mode == 0x01
                    ) ? 'evenodd' : 'nonzero';

                    break;
                }
                case RECORD_SET_STRETCH_BLT_MODE: {
                    var mode = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.setStretchBltMode(mode);

                    break;
                }
                case RECORD_SET_TEXT_CHARACTER_EXTRA: {
                    const extra = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.setTextCharacterExtra(extra);

                    break;
                }
                case RECORD_RESTORE_DC: {
                    const dc = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.restoreDC(dc);

                    break;
                }
                case RECORD_RESIZE_PALETTE: {
                    var objID = dv.getUint16(offset, true);
                    offset += 2;

                    // gdi.resizePalette((//gdiPalette) objs[objID]);

                    break;
                }
                case RECORD_DIB_CREATE_PATTERN_BRUSH: {
                    var usage = dv.getInt32(offset, true);
                    offset += 4;
                    // TODO
                    var image = new Int8Array(dv.buffer, offset, size * 2 - 4);

                    /*
                     for (var i = 0; i < objs.length; i++) {
                     if (objs[i] == null) {
                     objs[i] = //gdi.dibCreatePatternBrush(image, usage);
                     break;
                     }
                     }
                     */
                    break;
                }
                case RECORD_SET_LAYOUT: {
                    const layout = dv.getUint32(offset, true);
                    offset += 4;
                    // gdi.setLayout(layout);

                    break;
                }
                case RECORD_SET_BK_COLOR: {
                    var color = dv.getInt32(offset, true);
                    offset += 4;
                    color = Int32ToHexColor(color);
                    ctx.fillStyle = color;

                    break;
                }
                case RECORD_SET_TEXT_COLOR: {
                    var color = dv.getInt32(offset, true);
                    offset += 4;
                    color = Int32ToHexColor(color);
                    // ctx.fillStyle = color;
                    textColor = color;

                    break;
                }
                case RECORD_OFFSET_VIEWPORT_ORG_EX: {
                    var y = dv.getInt16(offset, true);
                    offset += 2;
                    var x = dv.getInt16(offset, true);
                    offset += 2;
                    // vox = x;
                    // voy = y;

                    break;
                }
                case RECORD_LINE_TO: {
                    var ey = dv.getInt16(offset, true);
                    offset += 2;
                    var ex = dv.getInt16(offset, true);
                    offset += 2;
                    ctx.lineTo(ex, ey);
                    ctx.stroke();

                    break;
                }
                case RECORD_MOVE_TO_EX: {
                    var y = dv.getInt16(offset, true);
                    offset += 2;
                    var x = dv.getInt16(offset, true);
                    offset += 2;
                    ctx.beginPath();
                    ctx.moveTo(x, y);

                    break;
                }
                case RECORD_OFFSET_CLIP_RGN: {
                    var y = dv.getInt16(offset, true);
                    offset += 2;
                    var x = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.offsetClipRgn(x, y);

                    break;
                }
                case RECORD_FILL_RGN: {
                    var brushID = dv.getUint16(offset, true);
                    offset += 2;
                    var rgnID = dv.getUint16(offset, true);
                    offset += 2;
                    // gdi.fillRgn((//gdiRegion) objs[rgnID], (//gdiBrush) objs[brushID]);

                    break;
                }
                case RECORD_SET_MAPPER_FLAGS: {
                    const flag = dv.getUint32(offset, true);
                    offset += 4;
                    // gdi.setMapperFlags(flag);

                    break;
                }
                case RECORD_SELECT_PALETTE: {
                    var mode = (
                        dv.getInt16(offset, true) != 0
                    );
                    offset += 2;
                    // if ((size * 2 - in.getCount()) > 0) {
                    // var objID = dv.getUint16(offset, true); offset += 2;;
                    // gdi.selectPalette((//gdiPalette) objs[objID], mode);
                    // }

                    break;
                }
                case RECORD_POLYGON: {
                    var numOfPoints = dv.getInt16(offset, true);
                    offset += 2;

                    ctx.beginPath();

                    var x = toAbsoluteX(dv.getInt16(offset, true), ww, wx, mx, wox, wsx);
                    offset += 2;
                    var y = toAbsoluteY(dv.getInt16(offset, true), wh, wy, my, woy, wsy);
                    offset += 2;
                    ctx.moveTo(x, y);

                    for (var i = 1; i < numOfPoints; i++) {
                        x = toAbsoluteX(dv.getInt16(offset, true), ww, wx, mx, wox, wsx);
                        offset += 2;
                        y = toAbsoluteY(dv.getInt16(offset, true), wh, wy, my, woy, wsy);
                        offset += 2;
                        ctx.lineTo(x, y);
                    }

                    ctx.closePath();
                    ctx.fill(fillMode);
                    ctx.stroke();

                    break;
                }
                case RECORD_POLYLINE: {
                    var numOfPoints = dv.getInt16(offset, true);
                    offset += 2;

                    ctx.beginPath();

                    var x = toAbsoluteX(dv.getInt16(offset, true), ww, wx, mx, wox, wsx);
                    offset += 2;
                    var y = toAbsoluteY(dv.getInt16(offset, true), wh, wy, my, woy, wsy);
                    offset += 2;
                    ctx.moveTo(x, y);

                    for (var i = 1; i < numOfPoints; i++) {
                        x = toAbsoluteX(dv.getInt16(offset, true), ww, wx, mx, wox, wsx);
                        offset += 2;
                        y = toAbsoluteY(dv.getInt16(offset, true), wh, wy, my, woy, wsy);
                        offset += 2;
                        ctx.lineTo(x, y);
                    }

                    ctx.stroke();

                    break;
                }
                case RECORD_SET_TEXT_JUSTIFICATION: {
                    const breakCount = dv.getInt16(offset, true);
                    offset += 2;
                    const breakExtra = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.setTextJustification(breakExtra, breakCount);

                    break;
                }
                case RECORD_SET_WINDOW_ORG_EX: {
                    wy = dv.getInt16(offset, true);
                    offset += 2;
                    wx = dv.getInt16(offset, true);
                    offset += 2;

                    break;
                }
                case RECORD_SET_WINDOW_EXT_EX: {
                    var height = dv.getInt16(offset, true);
                    offset += 2;
                    var width = dv.getInt16(offset, true);
                    offset += 2;

                    const inMemCanvas = document.createElement('canvas');
                    const inMemCtx = inMemCanvas.getContext('2d');
                    inMemCtx.drawImage(canvas, 0, 0);
                    canvas.width = Math.abs(width);
                    canvas.height = Math.abs(height);
                    ctx.drawImage(inMemCanvas, 0, 0);

                    ww = width;
                    wh = height;

                    break;
                }
                case RECORD_SET_VIEWPORT_ORG_EX: {
                    var y = dv.getInt16(offset, true);
                    offset += 2;
                    var x = dv.getInt16(offset, true);
                    offset += 2;
                    vx = x;
                    vy = y;

                    break;
                }
                case RECORD_SET_VIEWPORT_EXT_EX: {
                    var y = dv.getInt16(offset, true);
                    offset += 2;
                    var x = dv.getInt16(offset, true);
                    offset += 2;
                    vw = width;
                    vh = height;

                    break;
                }
                case RECORD_OFFSET_WINDOW_ORG_EX: {
                    var y = dv.getInt16(offset, true);
                    offset += 2;
                    var x = dv.getInt16(offset, true);
                    offset += 2;
                    vox = x;
                    voy = y;

                    break;
                }
                case RECORD_SCALE_WINDOW_EXT_EX: {
                    var yd = dv.getInt16(offset, true);
                    offset += 2;
                    var y = dv.getInt16(offset, true);
                    offset += 2;
                    var xd = dv.getInt16(offset, true);
                    offset += 2;
                    var x = dv.getInt16(offset, true);
                    offset += 2;
                    wsx = (
                        wsx * x
                    ) / xd;
                    wsy = (
                        wsy * y
                    ) / yd;

                    break;
                }
                case RECORD_SCALE_VIEWPORT_EXT_EX: {
                    var yd = dv.getInt16(offset, true);
                    offset += 2;
                    var y = dv.getInt16(offset, true);
                    offset += 2;
                    var xd = dv.getInt16(offset, true);
                    offset += 2;
                    var x = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.scaleViewportExtEx(x, xd, y, yd, null);

                    break;
                }
                case RECORD_EXCLUDE_CLIP_RECT: {
                    var ey = dv.getInt16(offset, true);
                    offset += 2;
                    var ex = dv.getInt16(offset, true);
                    offset += 2;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.excludeClipRect(sx, sy, ex, ey);

                    break;
                }
                case RECORD_INTERSECT_CLIP_RECT: {
                    var ey = dv.getInt16(offset, true);
                    offset += 2;
                    var ex = dv.getInt16(offset, true);
                    offset += 2;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.intersectClipRect(sx, sy, ex, ey);

                    break;
                }
                case RECORD_ELLIPSE: {
                    var ey = dv.getInt16(offset, true);
                    offset += 2;
                    var ex = dv.getInt16(offset, true);
                    offset += 2;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;

                    ctx.beginPath();
                    ctx.ellipse(ex, ey, sx, sy, 0, 0, Math.PI * 2);
                    ctx.fill();
                    ctx.stroke();

                    break;
                }
                case RECORD_FLOOD_FILL: {
                    var color = dv.getInt32(offset, true);
                    offset += 4;
                    var y = dv.getInt16(offset, true);
                    offset += 2;
                    var x = dv.getInt16(offset, true);
                    offset += 2;
                    color = Int32ToHexColor(color);
                    // gdi.floodFill(x, y, color);

                    break;
                }
                case RECORD_FRAME_RGN: {
                    var height = dv.getInt16(offset, true);
                    offset += 2;
                    var width = dv.getInt16(offset, true);
                    offset += 2;
                    var brushID = dv.getUint16(offset, true);
                    offset += 2;
                    var rgnID = dv.getUint16(offset, true);
                    offset += 2;

                    // gdi.frameRgn((//gdiRegion) objs[rgnID], (//gdiBrush) objs[brushID], width, height);
                    break;
                }
                case RECORD_ANIMATE_PALETTE: {
                    var entries = new Array(dv.getUint16(offset, true));
                    offset += 2;
                    var startIndex = dv.getUint16(offset, true);
                    offset += 2;
                    var objID = dv.getUint16(offset, true);
                    offset += 2;
                    for (var i = 0; i < entries.length; i++) {
                        entries[i] = dv.getInt32(offset, true);
                        offset += 4;
                    }

                    // gdi.animatePalette((//gdiPalette) objs[objID], startIndex, entries);
                    break;
                }
                case RECORD_TEXT_OUT: {
                    // TODO
                    /*
                     var count = dv.getInt16(offset, true); offset += 2;
                     byte[] text = in.readBytes(count);
                     if (count % 2 == 1) {
                     in.readByte();
                     }
                     var y = dv.getInt16(offset, true); offset += 2;
                     var x = dv.getInt16(offset, true); offset += 2;
                     //gdi.textOut(x, y, text);
                     */
                    break;
                }
                case RECORD_POLY_POLYGON: {
                    const numOfpolygons = dv.getInt16(offset, true);
                    offset += 2;
                    var numOfPoints = new Array(numOfpolygons);

                    for (var i = 0; i < numOfpolygons; i++) {
                        numOfPoints[i] = dv.getInt16(offset, true);
                        offset += 2;
                    }

                    for (var i = 0; i < numOfpolygons; i++) {
                        ctx.beginPath();

                        var x = toAbsoluteX(dv.getInt16(offset, true), ww, wx, mx, wox, wsx);
                        offset += 2;
                        var y = toAbsoluteY(dv.getInt16(offset, true), wh, wy, my, woy, wsy);
                        offset += 2;
                        ctx.moveTo(x, y);
                        //

                        for (let j = 1; j < numOfPoints[i]; j++) {
                            x = toAbsoluteX(dv.getInt16(offset, true), ww, wx, mx, wox, wsx);
                            offset += 2;
                            y = toAbsoluteY(dv.getInt16(offset, true), wh, wy, my, woy, wsy);
                            offset += 2;
                            ctx.lineTo(x, y);
                            //
                        }

                        ctx.closePath();
                        ctx.fill(fillMode);
                        ctx.stroke();
                    }

                    break;
                }
                case RECORD_EXT_FLOOD_FILL: {
                    const type = dv.getUint16(offset, true);
                    offset += 2;

                    var color = dv.getInt32(offset, true);
                    offset += 4;
                    var y = dv.getInt16(offset, true);
                    offset += 2;
                    var x = dv.getInt16(offset, true);
                    offset += 2;
                    color = Int32ToHexColor(color);
                    // gdi.extFloodFill(x, y, color, type);

                    break;
                }
                case RECORD_RECTANGLE: {
                    var ey = dv.getInt16(offset, true);
                    offset += 2;
                    var ex = dv.getInt16(offset, true);
                    offset += 2;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;
                    ctx.rect(sx, sy, ex - sx, ey - sy);
                    ctx.fill();
                    ctx.stroke();

                    break;
                }
                case RECORD_SET_PIXEL: {
                    var color = dv.getInt32(offset, true);
                    offset += 4;
                    var y = dv.getInt16(offset, true);
                    offset += 2;
                    var x = dv.getInt16(offset, true);
                    offset += 2;
                    color = Int32ToHexColor(color);
                    ctx.fillStyle = color;
                    ctx.fillRect(x, y, 1, 1);

                    break;
                }
                case RECORD_ROUND_RECT: {
                    const rh = dv.getInt16(offset, true);
                    offset += 2;
                    const rw = dv.getInt16(offset, true);
                    offset += 2;
                    var ey = dv.getInt16(offset, true);
                    offset += 2;
                    var ex = dv.getInt16(offset, true);
                    offset += 2;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;
                    drawRoundRect(
                        ctx,
                        sx,
                        sy,
                        ex - sx,
                        ey - sy,
                        (
                            rh + rw
                        ) / 2,
                        false,
                        true,
                    );

                    break;
                }
                case RECORD_PAT_BLT: {
                    var rop = dv.getUint32(offset, true);
                    offset += 4;
                    var height = dv.getInt16(offset, true);
                    offset += 2;
                    var width = dv.getInt16(offset, true);
                    offset += 2;
                    var y = dv.getInt16(offset, true);
                    offset += 2;
                    var x = dv.getInt16(offset, true);
                    offset += 2;
                    // gdi.patBlt(x, y, width, height, rop);

                    break;
                }
                case RECORD_SAVE_DC: {
                    // gdi.seveDC();

                    break;
                }
                case RECORD_PIE: {
                    var eyr = dv.getInt16(offset, true);
                    offset += 2;
                    var exr = dv.getInt16(offset, true);
                    offset += 2;
                    var syr = dv.getInt16(offset, true);
                    offset += 2;
                    var sxr = dv.getInt16(offset, true);
                    offset += 2;
                    var ey = dv.getInt16(offset, true);
                    offset += 2;
                    var ex = dv.getInt16(offset, true);
                    offset += 2;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;

                    drawPie(ctx, sx, sy, ex, ey, sxr, syr, exr, eyr);

                    break;
                }
                case RECORD_STRETCH_BLT: {
                    var rop = dv.getUint32(offset, true);
                    offset += 4;
                    var sh = dv.getInt16(offset, true);
                    offset += 2;
                    var sw = dv.getInt16(offset, true);
                    offset += 2;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;
                    var dh = dv.getInt16(offset, true);
                    offset += 2;
                    var dw = dv.getInt16(offset, true);
                    offset += 2;
                    var dy = dv.getInt16(offset, true);
                    offset += 2;
                    var dx = dv.getInt16(offset, true);
                    offset += 2;

                    // TODO
                    var image = new Int8Array(dv.buffer, offset, size * 2 - 20);

                    // gdi.stretchBlt(image, dx, dy, dw, dh, sx, sy, sw, sh, rop);
                    break;
                }
                case RECORD_ESCAPE: {
                    // TODO
                    // byte[] data = in.readBytes(2 * size);
                    // gdi.escape(data);

                    break;
                }
                case RECORD_INVERT_RGN: {
                    var rgnID = dv.getUint16(offset, true);
                    offset += 2;
                    // gdi.invertRgn((//gdiRegion) objs[rgnID]);

                    break;
                }
                case RECORD_PAINT_RGN: {
                    var objID = dv.getUint16(offset, true);
                    offset += 2;
                    // gdi.paintRgn((//gdiRegion) objs[objID]);

                    break;
                }
                case RECORD_SELECT_CLIP_RGN: {
                    var objID = dv.getUint16(offset, true);
                    offset += 2;
                    // gdiRegion rgn = (objID > 0) ? (//gdiRegion) objs[objID] : null;
                    // gdi.selectClipRgn(rgn);

                    break;
                }
                case RECORD_SELECT_OBJECT: {
                    var objID = dv.getUint16(offset, true);
                    offset += 2;
                    var obj = objs[objID];

                    switch (obj.type) {
                        case 'PEN':
                            ctx.lineWidth = obj.width;
                            ctx.strokeStyle = obj.color;

                            break;
                        case 'BRUSH':
                            ctx.fillStyle = obj.color;

                            break;
                        case 'FONT':
                            ctx.font = sprintf(
                                "%s%d %dpx '%s'", obj.italic ? 'italic ' : '',
                                obj.weight, Math.abs(obj.height), obj.faceName,
                            );

                            break;
                    }

                    break;
                }
                case RECORD_SET_TEXT_ALIGN: {
                    const align = dv.getInt16(offset, true);
                    offset += 2;

                    const alignH = align & (
                        0x00 | 0x06 | 0x02
                    );
                    const alignV = align & (
                        0x08 | 0x00 | 0x18
                    );

                    if (alignH == 0x02) {
                        ctx.textAlign = 'right';
                    } else if (alignH == 0x06) {
                        ctx.textAlign = 'center';
                    } else {
                        ctx.textAlign = 'left';
                    }

                    if (alignV == 0x08) {
                        ctx.textBaseline = 'bottom';
                    } else if (alignV == 0x00) {
                        ctx.textBaseline = 'top';
                    } else {
                        ctx.textBaseline = 'alphabetic';
                    }
                    /*
                     ctx.textBaseline = "middle";
                     ctx.textBaseline = "hanging";
                     */

                    break;
                }
                case RECORD_ARC: {
                    var eya = dv.getInt16(offset, true);
                    offset += 2;
                    var exa = dv.getInt16(offset, true);
                    offset += 2;
                    var sya = dv.getInt16(offset, true);
                    offset += 2;
                    var sxa = dv.getInt16(offset, true);
                    offset += 2;
                    var eyr = dv.getInt16(offset, true);
                    offset += 2;
                    var exr = dv.getInt16(offset, true);
                    offset += 2;
                    var syr = dv.getInt16(offset, true);
                    offset += 2;
                    var sxr = dv.getInt16(offset, true);
                    offset += 2;

                    // gdi.arc(sxr, syr, exr, eyr, sxa, sya, exa, eya);
                    break;
                }
                case RECORD_CHORD: {
                    var eya = dv.getInt16(offset, true);
                    offset += 2;
                    var exa = dv.getInt16(offset, true);
                    offset += 2;
                    var sya = dv.getInt16(offset, true);
                    offset += 2;
                    var sxa = dv.getInt16(offset, true);
                    offset += 2;
                    var eyr = dv.getInt16(offset, true);
                    offset += 2;
                    var exr = dv.getInt16(offset, true);
                    offset += 2;
                    var syr = dv.getInt16(offset, true);
                    offset += 2;
                    var sxr = dv.getInt16(offset, true);
                    offset += 2;

                    // gdi.chord(sxr, syr, exr, eyr, sxa, sya, exa, eya);
                    break;
                }
                case RECORD_BIT_BLT: {
                    var rop = dv.getUint32(offset, true);
                    offset += 4;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;
                    var height = dv.getInt16(offset, true);
                    offset += 2;
                    var width = dv.getInt16(offset, true);
                    offset += 2;
                    var dy = dv.getInt16(offset, true);
                    offset += 2;
                    var dx = dv.getInt16(offset, true);
                    offset += 2;

                    var dib = new Uint8Array(dv.buffer, offset, size * 2 - 16);
                    var base64 = Uint8ArrayToBase64(dibToBmp(dib));
                    drawBmpImage(ctx, base64, sx, sy, width, height, dx, dy, width, height, rop);

                    break;
                }
                case RECORD_EXT_TEXT_OUT: {
                    // TODO
                    let rsize = size;
                    var y = toAbsoluteY(dv.getInt16(offset, true), wh, wy, my, woy, wsy);
                    offset += 2;
                    var x = toAbsoluteX(dv.getInt16(offset, true), ww, wx, mx, wox, wsx);
                    offset += 2;

                    var count = dv.getInt16(offset, true);
                    offset += 2;
                    const options = dv.getUint16(offset, true);
                    offset += 2;

                    rsize -= 4;

                    let rect = null;

                    if ((
                        options & 0x0006
                    ) > 0) {
                        rect = new Array(4);
                        rect[0] = dv.getInt16(offset, true);
                        offset += 2;
                        rect[1] = dv.getInt16(offset, true);
                        offset += 2;
                        rect[2] = dv.getInt16(offset, true);
                        offset += 2;
                        rect[3] = dv.getInt16(offset, true);
                        offset += 2;
                        rsize -= 4;
                    }

                    var buffer = new Buffer(count);

                    for (var i = 0; i < count; i++) {
                        var c = dv.getInt8(offset++, true);
                        buffer[i] = c;
                    }

                    const text = Icnov.decode(buffer, charset);
                    /*
                     if (count % 2 == 1) {
                     dv.getInt8(offset++, true);
                     }
                     rsize -= (count + 1) / 2;

                     var dx = null;
                     if (rsize > 0) {
                     dx = new Array(rsize);
                     for (var i = 0; i < rsize; i++) {
                     dx[i] = dv.getInt16(offset, true);
                     offset += 2;
                     }
                     }
                     */
                    const fillStyle_bk = ctx.fillStyle;
                    ctx.fillStyle = textColor;
                    ctx.fillText(text, x, y);
                    ctx.fillStyle = fillStyle_bk;

                    break;
                }
                case RECORD_SET_DIBITS_TO_DEVICE: {
                    const colorUse = dv.getUint16(offset, true);
                    offset += 2;
                    const scanlines = dv.getUint16(offset, true);
                    offset += 2;
                    const startscan = dv.getUint16(offset, true);
                    offset += 2;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;
                    var dh = dv.getInt16(offset, true);
                    offset += 2;
                    var dw = dv.getInt16(offset, true);
                    offset += 2;
                    var dy = dv.getInt16(offset, true);
                    offset += 2;
                    var dx = dv.getInt16(offset, true);
                    offset += 2;

                    // TODO
                    // var image = new Int8Array(dv.buffer, offset, size * 2 - 18);
                    // gdi.setDIBitsToDevice(dx, dy, dw, dh, sx, sy, startscan, scanlines, image, colorUse);
                    break;
                }
                case RECORD_DIB_BIT_BLT: {
                    let isRop = false;
                    var rop = dv.getUint32(offset, true);
                    offset += 4;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;
                    var height = dv.getInt16(offset, true);
                    offset += 2;
                    if (height == 0) {
                        height = dv.getInt16(offset, true);
                        offset += 2;
                        isRop = true;
                    }

                    var width = dv.getInt16(offset, true);
                    offset += 2;
                    var dy = dv.getInt16(offset, true);
                    offset += 2;
                    var dx = dv.getInt16(offset, true);
                    offset += 2;

                    if (!isRop) {
                        var dib = new Uint8Array(dv.buffer, offset, size * 2 - 16);
                        var base64 = Uint8ArrayToBase64(dibToBmp(dib));
                        drawBmpImage(ctx, base64, sx, sy, width, height, dx, dy, width, height, rop);
                    }

                    break;
                }
                case RECORD_DIB_STRETCH_BLT: {
                    var rop = dv.getUint32(offset, true);
                    offset += 4;
                    var sh = dv.getInt16(offset, true);
                    offset += 2;
                    var sw = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var dh = dv.getInt16(offset, true);
                    offset += 2;
                    var dw = dv.getInt16(offset, true);
                    offset += 2;
                    var dy = dv.getInt16(offset, true);
                    offset += 2;
                    var dx = dv.getInt16(offset, true);
                    offset += 2;

                    var dib = new Uint8Array(dv.buffer, offset, size * 2 - 20);
                    var base64 = Uint8ArrayToBase64(dibToBmp(dib));

                    drawBmpImage(ctx, base64, sx, sy, sw, sh, dx, dy, dw, dh, rop);

                    break;
                }
                case RECORD_STRETCH_DIBITS: {
                    var rop = dv.getUint32(offset, true);
                    offset += 4;
                    var usage = dv.getUint16(offset, true);
                    offset += 2;
                    var sh = dv.getInt16(offset, true);
                    offset += 2;
                    var sw = dv.getInt16(offset, true);
                    offset += 2;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;
                    var dh = dv.getInt16(offset, true);
                    offset += 2;
                    var dw = dv.getInt16(offset, true);
                    offset += 2;
                    var dy = dv.getInt16(offset, true);
                    offset += 2;
                    var dx = dv.getInt16(offset, true);
                    offset += 2;

                    var dib = new Uint8Array(dv.buffer, offset, size * 2 - 22);
                    var base64 = Uint8ArrayToBase64(dibToBmp(dib));

                    drawBmpImage(ctx, base64, sx, sy, sw, sh, dx, dy, dw, dh, rop);

                    break;
                }
                case RECORD_DELETE_OBJECT: {
                    var objID = dv.getUint16(offset, true);
                    offset += 2;
                    objs[objID] = null;

                    break;
                }
                case RECORD_CREATE_PALETTE: {
                    const version = dv.getUint16(offset, true);
                    offset += 2;
                    var entries = new Array(dv.getUint16(offset, true));
                    offset += 2;
                    for (var i = 0; i < entries.length; i++) {
                        entries[i] = dv.getInt32(offset, true);
                        offset += 4;
                    }

                    insertObjToFirstNull(objs, {
                        'type':    'PALETTE',
                        'entries': entries,
                    });

                    break;
                }
                case RECORD_CREATE_PATTERN_BRUSH: {
                    var image = new Int8Array(dv.buffer, offset, size * 2);

                    insertObjToFirstNull(objs, {
                        'type':  'PATTERN_BRUSH',
                        'image': image,
                    });

                    break;
                }
                case RECORD_CREATE_PEN_INDIRECT: {
                    var style = dv.getUint16(offset, true);
                    offset += 2;
                    var width = dv.getInt16(offset, true);
                    offset += 2;
                    dv.getInt16(offset, true);
                    offset += 2;
                    var color = Int32ToHexColor(dv.getInt32(offset, true));
                    offset += 4;

                    insertObjToFirstNull(objs, {
                        'type':  'PEN',
                        'style': style,
                        'color': color,
                        'width': width,
                    });

                    break;
                }
                case RECORD_CREATE_FONT_INDIRECT: {
                    var height = dv.getInt16(offset, true);
                    offset += 2;
                    var width = dv.getInt16(offset, true);
                    offset += 2;
                    const escapement = dv.getInt16(offset, true);
                    offset += 2;
                    const orientation = dv.getInt16(offset, true);
                    offset += 2;
                    const weight = dv.getInt16(offset, true);
                    offset += 2;
                    const italic = (
                        dv.getInt8(offset, true) == 1
                    );
                    offset++;
                    const underline = (
                        dv.getInt8(offset, true) == 1
                    );
                    offset++;
                    const strikeout = (
                        dv.getInt8(offset, true) == 1
                    );
                    offset++;
                    charset = dv.getUint8(offset, true);
                    offset++;
                    const outPrecision = dv.getInt8(offset, true);
                    offset++;
                    const clipPrecision = dv.getInt8(offset, true);
                    offset++;
                    const quality = dv.getInt8(offset, true);
                    offset++;
                    const pitchAndFamily = dv.getInt8(offset, true);
                    offset++;

                    // Convert from an encoded buffer to js string.
                    var count = size * 2 - 18;
                    var buffer = new Buffer(count);

                    for (var i = 0; i < count; i++) {
                        var c = dv.getInt8(offset++, true);

                        if (c == 0) {
                            break;
                        }
                        buffer[i] = c;
                    }
                    charset = getCharset(charset);
                    const faceName = Icnov.decode(buffer, charset).replace(/\u0000/g, '');
                    var obj = {
                        'type':           'FONT',
                        'faceName':       faceName,
                        'height':         height,
                        'width':          width,
                        'escapement':     escapement,
                        'orientation':    orientation,
                        'weight':         weight,
                        'italic':         italic,
                        'underline':      underline,
                        'strikeout':      strikeout,
                        'charset':        charset,
                        'outPrecision':   outPrecision,
                        'clipPrecision':  clipPrecision,
                        'quality':        quality,
                        'pitchAndFamily': pitchAndFamily,
                    };

                    insertObjToFirstNull(objs, obj);

                    break;
                }
                case RECORD_CREATE_BRUSH_INDIRECT: {
                    var style = dv.getUint16(offset, true);
                    offset += 2;
                    var color = Int32ToHexColor(dv.getInt32(offset, true));
                    offset += 4;
                    const hatch = dv.getUint16(offset, true);
                    offset += 2;

                    insertObjToFirstNull(objs, {
                        'type':  'BRUSH',
                        'style': style,
                        'color': color,
                        'hatch': hatch,
                    });

                    break;
                }
                case RECORD_CREATE_RECT_RGN: {
                    var ey = dv.getInt16(offset, true);
                    offset += 2;
                    var ex = dv.getInt16(offset, true);
                    offset += 2;
                    var sy = dv.getInt16(offset, true);
                    offset += 2;
                    var sx = dv.getInt16(offset, true);
                    offset += 2;

                    insertObjToFirstNull(objs, {
                        'type': 'RECT_RGN',
                        'sx':   sx,
                        'sy':   sy,
                        'ex':   ex,
                        'ey':   ey,
                    });

                    break;
                }
                default: {
                    console.info(`unsuppored id find: ${id} (size=${size})`);
                }
            }

            offset = offset_bk + size * 2;
        }
    }
};

WMFConverter.prototype.toPng = function(filename) {
    // TODO
};

WMFConverter.prototype.toSvg = function(filename) {
    // TODO
};

WMFConverter.prototype.getExeTime = function() {
    return this.executeTime;
};

module.exports = WMFConverter;
