//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import _                from 'lodash';
import I18n             from 'i18next';
import ComponentHelper  from '@/helper/ComponentHelper';
import MenuButton       from '@/components/stateless/atomic/MenuButton';
import Link             from '@/components/stateless/atomic/Link';
import Spacer           from '@/components/stateless/atomic/Spacer';
import Routes           from '@/constants/Routes';
import FileUploadButton from '@/components/connected/FileUploadButton';
import PropTypes        from '@/components/PropTypes';
import VersionNumber    from '@/components/stateless/atomic/VersionNumber';
import chartWerkerLogo  from '@/assets/images/chartwerker-logo-vertical.png';
import styles           from './styles.module.scss';

export default class MainMenu extends React.Component {
    static propTypes = {
        closeFileButtonPressed: PropTypes.func,
        openFileName:           PropTypes.string,
    };

    static defaultProps = {
        closeFileButtonPressed: _.noop,
        openFileName:           null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <div
                className={styles.mainMenu}
            >
                <h1>
                    <Link to={Routes.home}>
                        <img
                            src={chartWerkerLogo}
                            alt={`${I18n.t('chartwerker')} ${I18n.t('logo')}`}
                        />
                        {I18n.t('chartwerker')}
                    </Link>
                    <VersionNumber />
                </h1>
                <Spacer height={12} />
                <h2 className={styles.slogan}>
                    <strong>
                        {I18n.t('sloganPart1')}
                    </strong>
                    {I18n.t('sloganPart2')}
                    <br />
                    <strong>
                        {I18n.t('sloganPart3')}
                    </strong>
                    <br />
                    {I18n.t('sloganPart4')}
                    <strong>
                        {I18n.t('sloganPart5')}
                        <span aria-hidden="true">
                            {' | '}
                        </span>
                    </strong>
                    {I18n.t('sloganPart6')}
                    <br />
                    {I18n.t('sloganPart7')}
                    <strong>
                        {I18n.t('sloganPart8')}
                    </strong>
                </h2>
                <Spacer height={22} />
                <ul className={styles.menuButtonWrapper}>
                    <MenuButton
                        subText={I18n.t('openFileSubText')}
                        text={I18n.t('openFile')}
                    >
                        <FileUploadButton />
                    </MenuButton>
                    {this.renderOpenFile()}
                    <MenuButton
                        subText={null}
                        text={I18n.t('help')}
                        to={Routes.help}
                    />
                    <MenuButton
                        subText={null}
                        text={I18n.t('imprint')}
                        to={Routes.imprint}
                    />
                    <MenuButton
                        subText={null}
                        text={I18n.t('dataProtection')}
                        to={Routes.dataProtection}
                    />
                </ul>
                <Spacer height={48} />
                <p className={styles.copyright}>
                    {I18n.t('copyrightPart1')}
                    <a
                        href={I18n.t('chartwerkWebsite')}
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        {I18n.t('copyrightPart2')}
                    </a>
                </p>
                <Spacer height={10} />
                <ul className={styles.socialMediaLinks}>
                    <li className={styles.socialMediaLinkFacebook}>
                        <a
                            href={I18n.t('socialMediaLinkFacebook')}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            {I18n.t('facebook')}
                        </a>
                    </li>
                    <li className={styles.socialMediaLinkLinkedIn}>
                        <a
                            href={I18n.t('socialMediaLinkLinkedIn')}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            {I18n.t('linkedIn')}
                        </a>
                    </li>
                    <li className={styles.socialMediaLinkXing}>
                        <a
                            href={I18n.t('socialMediaLinkXing')}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            {I18n.t('xing')}
                        </a>
                    </li>
                </ul>
            </div>
        );
    }

    renderOpenFile = () => {
        const { closeFileButtonPressed, openFileName } = this.props;

        if (openFileName) {
            return (
                <>
                    <MenuButton
                        subText={openFileName}
                        text={I18n.t('showResult')}
                        to={Routes.analyse}
                    />
                    <MenuButton
                        onClick={closeFileButtonPressed}
                        text={I18n.t('closeFile')}
                    />
                </>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}
