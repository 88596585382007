//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import { connect }                   from 'react-redux';
import { bindActionCreators }        from 'redux';
import ComponentHelper               from '@/helper/ComponentHelper';
import PropTypes                     from '@/components/PropTypes';
import FileUploadButtonStateless     from '@/components/stateless/atomic/FileUploadButton';
import { PresentationParserActions } from '@/store/actions/presentationParser';

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(PresentationParserActions, dispatch);
};

class FileUploadButton extends React.Component {
    static propTypes = {
        processFile: PropTypes.func,
    };

    static defaultProps = {
        processFile: null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    onFileChanged = (event) => {
        const { files } = event.target;

        console.log('FileUploadButton: onFileChanged', event, files);

        if (files.length > 0) {
            const file = files[0];

            this.props.processFile({
                file,
            });
        }
    };

    render() {
        return (
            <FileUploadButtonStateless
                onFileChanged={this.onFileChanged}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default connect(null, mapDispatchToProps)(FileUploadButton);
