//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                 from 'react';
import { connect }           from 'react-redux';
import ComponentHelper       from '@/helper/ComponentHelper';
import PropTypes             from '@/components/PropTypes';
import AnalysisInfoStateless from '@/components/stateless/atomic/AnalysisInfo';
import _                     from 'lodash';

const mapStateToProps = (state) => {
    const imageCount          = _.get(state, 'presentationParser.totalImageCount', 0);
    const imageDuplicateCount = _.get(state, 'presentationParser.totalDuplicateImageCount', 0);
    const slideCount          = _.get(state, 'presentationParser.slides', 0);
    const masterSlideCount    = _.get(state, 'presentationParser.masterSlides', 0);
    const fileSize            = _.get(state, 'presentationParser.fileSize', 0);

    return {
        fileSize,
        imageCount,
        imageDuplicateCount,
        masterSlideCount,
        slideCount,
    };
};

class AnalysisInfo extends React.Component {
    static propTypes = {
        fileSize:            PropTypes.number,
        imageCount:          PropTypes.number,
        imageDuplicateCount: PropTypes.number,
        masterSlideCount:    PropTypes.number,
        slideCount:          PropTypes.number,
    };

    static defaultProps = {
        fileSize:            null,
        imageCount:          null,
        imageDuplicateCount: null,
        masterSlideCount:    null,
        slideCount:          null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <AnalysisInfoStateless
                fileSize={this.props.fileSize}
                imageCount={this.props.imageCount}
                imageDuplicateCount={this.props.imageDuplicateCount}
                masterSlideCount={this.props.masterSlideCount}
                slideCount={this.props.slideCount}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default connect(mapStateToProps, null)(AnalysisInfo);
