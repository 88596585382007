//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import { connect }                   from 'react-redux';
import { bindActionCreators }        from 'redux';
import ComponentHelper               from '@/helper/ComponentHelper';
import PropTypes                     from '@/components/PropTypes';
import GlobalDropZoneStateless       from '@/components/stateless/atomic/GlobalDropZone';
import { PresentationParserActions } from '@/store/actions/presentationParser';

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(PresentationParserActions, dispatch);
};

class GlobalDropZone extends React.Component {
    static propTypes = {
        children:    PropTypes.children,
        processFile: PropTypes.func,
    };

    static defaultProps = {
        children:    null,
        processFile: null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    onFileAccepted = (file) => {
        this.props.processFile({
            file,
        });
    };

    render() {
        return (
            <GlobalDropZoneStateless
                onFileAccepted={this.onFileAccepted}
            >
                {this.props.children}
            </GlobalDropZoneStateless>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default connect(null, mapDispatchToProps)(GlobalDropZone);
