//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n            from 'i18next';
import moment          from 'moment';
import preval          from 'preval.macro';
import React           from 'react';
import _               from 'lodash';
import ComponentHelper from '@/helper/ComponentHelper';
import gitHookCommitId from '@/constants/Git';
import styles          from './styles.module.scss';
import packageJson     from '../../../../../package.json';

const buildTimestamp   = preval`module.exports = new Date().getTime();`;
const prevalCommitHash = preval`
    const fs = require('fs');
    const rev = fs.readFileSync('.git/HEAD').toString();

    if (rev.indexOf(':') === -1) {
        module.exports = rev;
    } else {
        module.exports = fs.readFileSync('.git/' + rev.substring(5).trim()).toString();
    }
`;

export class VersionNumber extends React.Component {
    static propTypes = {};

    static defaultProps = {};

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    /**
     * This method tries to get the latest commit hash that was generated when the react application was built.
     * If that failed, it will fallback on the last known commit id generated by the "post merge" commit hook.
     *
     * @returns {string|*}
     */
    getCommitHash = () => {
        if (_.isString(prevalCommitHash)) {
            return prevalCommitHash;
        }

        return gitHookCommitId;
    };

    getDateString = () => {
        const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
        const formattedDate    = lastUpdateMoment.format(I18n.t('versionNumberDateFormat'));

        return formattedDate;
    };

    render() {
        const commitHash          = this.getCommitHash();
        const commitHashHashShort = commitHash.substring(0, 8);
        const versionShort        = packageJson.version;
        const versionLong         = `${versionShort}.${buildTimestamp} (${this.getDateString()} ${commitHashHashShort})`;

        return (
            <div className={styles.versionNumberWrapper}>
                <span
                    title={versionLong}
                    className={styles.versionNumber}
                >
                    {versionShort}
                </span>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default VersionNumber;
