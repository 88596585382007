//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import classNames                    from 'classnames';
import _                             from 'lodash';
import ComponentHelper               from '@/helper/ComponentHelper';
import PropTypes                     from '@/components/PropTypes';
import NavigationLinkOrClickCallback from '@/components/stateless/composed/NavigationLinkOrClickCallback';
import styles                        from './styles.module.scss';

export class MenuButton extends React.Component {
    static propTypes = {
        children: PropTypes.children,
        onClick:  PropTypes.func,
        subText:  PropTypes.string,
        text:     PropTypes.string,
        to:       PropTypes.string,
    };

    static defaultProps = {
        children: null,
        onClick:  _.noop,
        subText:  '',
        text:     '',
        to:       null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <li
                className={classNames(
                    styles.menuButton,
                    {
                        [styles.withSubText]: this.props.subText,
                    },
                )}
            >
                <span>
                    {this.renderLinkOrFallback()}
                </span>
            </li>
        );
    }

    renderContent = () => {
        return (
            <>
                {this.props.children}
                {this.props.text}
                {this.renderSubText()}
            </>
        );
    };

    renderLinkOrFallback = () => {
        const { onClick, to } = this.props;
        const content         = this.renderContent();

        if (
            to ||
            onClick
        ) {
            return (
                <NavigationLinkOrClickCallback
                    onClick={onClick}
                    to={to}
                >
                    {content}
                </NavigationLinkOrClickCallback>
            );
        }

        return content;
    };

    renderSubText = () => {
        if (this.props.text) {
            return (
                <span>
                    {this.props.subText}
                </span>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default MenuButton;
