//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                      from 'immutability-helper';
import { LightBoxTypes }           from '@/store/actions/lightBox';
import { PresentationParserTypes } from '@/store/actions/presentationParser';

const initialState = Object.freeze({
    imageCount:         0,
    open:               false,
    storeId:            null,
});

const close = (action, state) => {
    return update(state, {
        imageIndex: {
            $set: action.imageIndex,
        },
        open:       {
            $set: false,
        },
    });
};

const open = (action, state) => {
    return update(state, {
        open: {
            $set: true,
        },
    });
};

const openWithStoreId = (action, state) => {
    return update(state, {
        open:    {
            $set: true,
        },
        storeId: {
            $set: action.storeId,
        },
    });
};

const processFileSucceeded = (action, state) => {
    const { dataBuffer } = action;
    const imageCount     = dataBuffer.images.length;

    return update(state, {
        imageCount: {
            $set: imageCount,
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case LightBoxTypes.CLOSE:                            return close(action, state);
        case LightBoxTypes.OPEN:                             return open(action, state);
        case LightBoxTypes.OPEN_WITH_STORE_ID:               return openWithStoreId(action, state);
        case PresentationParserTypes.PROCESS_FILE_SUCCEEDED: return processFileSucceeded(action, state);
        default:                                             return state;
        // @formatter:on
    }
}
