//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import DomEvents from '@/constants/DomEvents';

export default function withWindowDimensions(WrappedComponent) {
    class Component extends React.Component {
        static defaultStates = {
            windowHeight: 0,
            windowWidth:  0,
        };

        static propTypes = [];

        static defaultProps = [];

        static renderAffectingProps = [];

        static renderAffectingStates = Object.keys(this.defaultStates);

        constructor(props) {
            super(props);

            this.state = {
                ...Component.defaultStates,
            };
        }

        componentDidMount() {
            this.updateWindowDimensions();

            window.addEventListener(DomEvents.resize, this.updateWindowDimensions);
        }

        componentWillUnmount() {
            window.removeEventListener(DomEvents.resize, this.updateWindowDimensions);
        }

        shouldComponentUpdate(nextProps, nextState) {
            // This has to be true since otherwise
            // sub components will not render properly
            return true;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    windowWidth={this.state.windowWidth}
                    windowHeight={this.state.windowHeight}
                />
            );
        }

        updateWindowDimensions = () => {
            this.setState({
                windowHeight: window.innerHeight,
                windowWidth:  window.innerWidth,
            });
        };
    }

    return Component;
}
