//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import React            from 'react';
import * as Sentry      from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Environment      from '@/helper/Environment';

function getTracesSampleRate() {
    if (Environment.isProduction()) {
        return 0.25;
    }

    return 1;
}

Sentry.init({
    dsn:          'https://7569028329604af49f0582b93e78d383@o683776.ingest.sentry.io/5771309',
    integrations: [
        new Integrations.BrowserTracing(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: getTracesSampleRate(),
});

export default function renderCrashButton() {
    return (
        <button
            onClick={() => {
                const foo = 'foo';

                console.log(foo.this.does.not.exist);
            }}
        >
            Crash
        </button>
    );
}
// TODO-Skeleton end
