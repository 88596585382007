//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const german = Object.freeze({
    analyse:                  'Analyse',
    analyseEmpty:             'Keine Dateien zum Anzeigen. Bitte laden Sie eine Präsentation hoch, die Bilder enthält.',
    analyseInfoPart1:         'In dieser PowerPoint',
    analyseInfoPart1Many:     'befinden sich',
    analyseInfoPart1No:       'befindet sich',
    analyseInfoPart1Single:   'befindet sich',
    analyseInfoPart2Many:     '{{imageCount}} Bilder',
    analyseInfoPart2No:       'kein Bild',
    analyseInfoPart2Single:   'ein Bild',
    analyseInfoPart3:         'und',
    analyseInfoPart4Many:     '{{slideCount}} Bearbeitungsfolien',
    analyseInfoPart4No:       'keine Bearbeitungsfolien',
    analyseInfoPart4Single:   'eine Bearbeitungsfolien',
    analyseInfoPart5:         'sowie',
    analyseInfoPart6Many:     '{{masterSlideCount}} Masterlayouts.',
    analyseInfoPart6No:       'keine Masterlayouts.',
    analyseInfoPart6Single:   'eine Masterlayouts.',
    analyseInfoPart7:         'Die Datei hat eine Größe von ',
    and:                      'und',
    backToHomePage:           'Zurück zur Startseite',
    chartwerker:              'chartwerker',
    chartwerkMail:            'info@chartwerk.de',
    chartwerkWebsite:         'https://chartwerk.de',
    closeFile:                'Schließen',
    copyrightPart1:           'Dieses kostenlose Tool wird Ihnen bereitgestellt von',
    copyrightPart2:           'chartwerk® Präsentationen GbR',
    dataProtection:           'Datenschutzerklärung',
    dropZoneText1:            'Datei öffnen',
    dropZoneText2:            'oder auf Fenster ziehen',
    error404:                 '404',
    facebook:                 'Facebook',
    fileName:                 'Dateiname',
    fileSize:                 'Dateigröße',
    fileType:                 'Dateityp',
    globalDropZoneText1:      'PowerPoint-Datei hier hin ziehen',
    globalDropZoneText2:      'Unterstützte Formate: .pptx',
    help:                     'Hilfe',
    homePage:                 'Startseite',
    homeSloganPart1:          'Bildeinsatz ',
    homeSloganPart2:          'in ',
    homeSloganPart3:          'PowerPoint',
    homeSloganPart4:          'Finden Sie ',
    homeSloganPart5:          'grosse Bilder',
    homeSloganPart6:          ' in Ihrer',
    homeSloganPart7:          'Präsentation',
    homeText1:                'Je umfangreicher dein Bildeinsatz in der Präsentation, desto höher die Dateigröße. Auch beim Einsatz weniger Bilder kann ein einziger Scan oder eine unscheinbare Grafik, z. B. im TIFF-, PNG-, PSD- oder BMP-Format eine Datei anwachsen lassen.',
    homeText2:                'Mit chartwerker® von chartwerk® Präsentationen GbR erhältst du schnell und einfach eine klar strukturierte Ansicht aller enthaltenen und auch doppelt eingesetzten Bilder in PPT mit Angabe der Bildgröße in sämtlichen Master- und Bearbeitungsfolien.',
    homeText3:                'Die Bilder werden in einer Vorschau mit Bildnamen, Auflösung, Dateigröße und Bildformat angegeben. Sortiere z. B. nach Dateigröße oder Seitenzahl, sieh sofort, auf welcher Seite sich Bilder mit hoher Auflösung oder Größe befinden.',
    homeText4:                'PowerPoint bietet die Möglichkeit, die Bilder in verschiedenen Auflösungen für den Druck, die Anzeige auf dem Bildschirm oder den Versand per E-Mail zu komprimieren. Beachte bitte, dass dadurch auch die Bildqualität beeinträchtigt wird.',
    homeText5:                'Viel Spass mit unserem Tool wünschen die chartwerker:innen',
    areIdentical:             '  sind identisch.',
    imagePreview:             'Bildvorschau',
    imageResolution:          'Bildauflösung',
    images:                   'Bilder',
    imprint:                  'Impressum',
    linkedIn:                 'LinkedIn',
    loadingProcessingFile:    'Verarbeite Präsentation...',
    logo:                     'Logo',
    lulububuLink:             'https://lulububu.de',
    masterSlide:              'Master',
    more:                     'Mehr',
    no:                       'Nein',
    oclock:                   'Uhr',
    outOf:                    'von',
    openFile:                 'Datei öffnen',
    openFileSubText:          'oder auf Fenster ziehen',
    or:                       'oder',
    pageNotFound:             'Die Seite wurde nicht gefunden.',
    pageTitle:                'Große Bilder in PowerPoint finden | chartwerker®',
    parseFileError:           'Beim Verarbeiten Ihrer Datei ist leider ein Fehler aufgetreten. Bitte überprüfen Sie die Datei und kontaktieren Sie uns im Zweifel direkt!',
    pictureCompletelyOutside: 'Das Bild ist vollständig außerhalb des Folie.',
    picturePartlyOutside:     'Das Bild ist teilweise außerhalb des Folie.',
    pixelShort:               'px',
    showResult:               'Ergebnis anzeigen',
    slide:                    'Folie',
    slides:                   'Folien',
    sloganPart1:              'Business- ',
    sloganPart2:              'und ',
    sloganPart3:              'Imagepräsentationen ',
    sloganPart4:              'mit ',
    sloganPart5:              'Biss',
    sloganPart6:              'knackig scharf auf',
    sloganPart7:              'den ',
    sloganPart8:              'Punkt',
    socialMediaLinkFacebook:  'https://www.facebook.com/chartwerk',
    socialMediaLinkLinkedIn:  'https://www.linkedin.com/in/corinna-bende-chartwerk-pr%C3%A4sentationen-gbr-82077722',
    socialMediaLinkXing:      'https://www.xing.com/pages/chartwerk-prasentationengbr-business-undimageprasentationenmitbiss',
    unparsableImage:          'Das Format des Bildes wird nicht unterstützt.',
    vectorFile:               'Vektordatei',
    versionNumberDateFormat:  'DD.MM.YYYY HH:mm:ss',
    xing:                     'Xing',
    yes:                      'Ja',
});

module.exports = german;
