//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator }                from '@/helper/Store';
import { makeActionCreatorIncreaseLoading } from '@/helper/Store';
import { makeActionCreatorDecreaseLoading } from '@/helper/Store';

const PresentationParserTypes = Object.freeze({
    CLOSE_FILE:             'PresentationParser/CLOSE_FILE',
    PROCESS_FILE:           'PresentationParser/PROCESS_FILE',
    PROCESS_FILE_FAILED:    'PresentationParser/PROCESS_FILE_FAILED',
    PROCESS_FILE_SUCCEEDED: 'PresentationParser/PROCESS_FILE_SUCCEEDED',
});

const closeFile = makeActionCreator(
    PresentationParserTypes.CLOSE_FILE,
    {},
);

const processFile = makeActionCreatorIncreaseLoading(
    PresentationParserTypes.PROCESS_FILE,
    {
        file: null,
    },
);

const processFileFailed = makeActionCreatorDecreaseLoading(
    PresentationParserTypes.PROCESS_FILE_FAILED,
    {},
);

const processFileSucceeded = makeActionCreatorDecreaseLoading(
    PresentationParserTypes.PROCESS_FILE_SUCCEEDED,
    {
        dataBuffer: null,
    },
);

const PresentationParserActions = Object.freeze({
    closeFile,
    processFile,
    processFileFailed,
    processFileSucceeded,
});

export {
    PresentationParserActions,
    PresentationParserTypes,
};
