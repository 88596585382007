//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import classNames      from 'classnames';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export default class LoadingOverlay extends React.Component {
    static propTypes = {
        isLoading:             PropTypes.bool,
        loadingOverlayClicked: PropTypes.func,
        text:                  PropTypes.string,
    };

    static defaultProps = {
        isLoading:             false,
        loadingOverlayClicked: _.noop,
        text:                  '',
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        const { loadingOverlayClicked, isLoading, text } = this.props;

        return (
            <div
                className={classNames(
                    styles.loadingOverlay,
                    {
                        [styles.loadingOverlayVisible]: isLoading,
                    },
                )}
            >
                <div
                    className={styles.loadingOverlayPulse}
                    aria-label={text}
                >
                    <span onClick={loadingOverlayClicked} />
                    <span onClick={loadingOverlayClicked} />
                </div>
                <span
                    className={styles.loadingOverlayTextWrapper}
                >
                    {text}
                </span>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}
