//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n                        from 'i18next';
import update                      from 'immutability-helper';
import { REHYDRATE }               from 'redux-persist';
import { LOCATION_CHANGE }         from 'connected-react-router';
import { LoadingTypes }            from '@/store/actions/loading';
import { PresentationParserTypes } from '@/store/actions/presentationParser';
import LoadingLevel                from '@/constants/LoadingLevel';

const initialState = Object.freeze({
    clickCount: 0,
    imagesLeft: 0,
    isLoading:  false,
    level:      0,
    slidesLeft: 0,
});

const getLoadingTextForType = (type) => {
    switch (type) {
        case PresentationParserTypes.PROCESS_FILE:
            return I18n.t('loadingProcessingFile');
    }

    console.warn('⚠️⚠️⚠️ Warning: Default loading text is used ⚠️⚠️⚠️');

    return I18n.t('loading');
};

const increaseClickCount = (state) => {
    return update(state, {
        clickCount: {
            $set: state.clickCount + 1,
        },
    });
};

const resetClickCount = (state) => {
    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
    });
};

const setProgressLeft = (state, action) => {
    return update(state, {
        imagesLeft: {
            $set: action.imagesLeft,
        },
        slidesLeft: {
            $set: action.slidesLeft,
        },
    });
};

const resetOverlay = (state) => {
    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
        isLoading:  {
            $set: initialState.isLoading,
        },
        level:      {
            $set: initialState.level,
        },
    });
};

const decreaseLevel = (state) => {
    const level     = Math.max(state.level - 1, 0);
    const isLoading = !(
        level === 0
    );

    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
        isLoading:  {
            $set: isLoading,
        },
        level:      {
            $set: level,
        },
    });
};

const increaseLevel = (state, action) => {
    const levelIncrease = 1;

    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
        isLoading:  {
            $set: true,
        },
        level:      {
            $set: state.level + levelIncrease,
        },
        text:       {
            $set: (
                action.text || getLoadingTextForType(action.type)
            ),
        },
    });
};

const rehydrate = (state) => {
    return update(state, {
        isLoading: {
            $set: false,
        },
        level:     {
            $set: 0,
        },
    });
};

export default (state = initialState, action) => {
    const type         = action.type;
    const loadingLevel = action.loadingLevel;

    if (LoadingLevel.increase === loadingLevel) {
        return increaseLevel(state, action);
    }

    if (LoadingLevel.decrease === loadingLevel) {
        return decreaseLevel(state, action);
    }

    switch (type) {
        // @formatter:off
        case LoadingTypes.OVERLAY_CLICKED:        return increaseClickCount(state, action);
        case LoadingTypes.RESET_OVERLAY:          return resetOverlay(state, action);
        case LoadingTypes.SET_PROGRESS_LEFT:      return setProgressLeft(state, action);
        case LOCATION_CHANGE:                     return resetClickCount(state, action);
        case REHYDRATE:                           return rehydrate(state, action);
        // @formatter:on
    }

    return state;
};
